@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.certified{
    display: flex;
    align-items: center;
    gap: spacing(0.5);
    padding: spacing(0.75);
    background: rgba($color: #000000, $alpha: 0.5);
    border-radius: $borderRadius;
    & .icon{
        font-size: 16px;
        color: #FCFCFC;
    }
    & > p{
        font-size: 12px;
        font-weight: 400;
        color: #FCFCFC;
        margin: 0;
    }
}