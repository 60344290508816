@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.fileUploader{
    flex: 1;
    width: 100%;
    &.fullWidth{
        flex: unset;
        width: 568px;
        margin-right: 16px;
    }
    &.scroll{
        overflow-y: auto;
        overflow-x: hidden;
    }
    &.empty{
        & .selector{
            width: 100%;
            height: 100%;
        }
    }
    & .selector{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 3px dashed $borderColor;
        border-radius: spacing(1);
        &:hover{
            background: rgba($color: #000000, $alpha: 0.025);
        }
        &:active{
            background: rgba($color: #000000, $alpha: 0.05);
        }
        & .icon{
            width: 62px;
            color: $fontColor;
        }
        & > p{
            font-size: $extraSmallFontSize;
            font-weight: 400;
            color: $fontColor;
            max-width: 114px;
            text-align: center;
        }
        & .fileSelector{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
            z-index: 2;
        }
    }
    & .content{
        display: flex;
        position: relative;
        gap: spacing(3);
        padding: 0 spacing(2) spacing(2) spacing(2);
        max-height: 512px;
        & .fileContainer{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: spacing(1.5);
            &.bigFileContainer{
                gap: spacing(3);
            }
        }
        & .cover{
            position: absolute;
            //restar 16 tal vez
            left: 208px;
            top: -8px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: spacing(1);
            z-index: 3;
            background-color: transparent;
            & p{
                font-size: $smallFontSize;
                font-weight: 500;
                color: $fontColor;
                margin: 0;
            }
            &.bigCover{
                left: 384px;
            }
        }
        & .item:hover{
             cursor: pointer;   
        }
        & .item{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 164px;
            height: 214px;
            &.isLoading,
            &.failure{
                & img{
                    opacity: 0.3;
                }
            }
            &.bigPictures{
                width: 260px !important;
                height: 250px !important;
            }
            &.bigSelector{
                width: 252px;
                height: 240px;
            }
            & .error{
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: spacing(0.5);
                padding: spacing(0.5);
                z-index: 2;
                user-select: none;
                & > .icon{
                    padding: spacing(1.25);
                    width: 32px;
                    color: $secondFontColor;
                    background: rgba($color: #000000, $alpha: 0.65);
                    border-radius: 100px;
                }
                & > p{
                    font-size: $extraSmallFontSize;
                    font-weight: 500;
                    color: $fontColor;
                    text-align: center;
                    margin: 0;
                }
            }
            & .index{
                position: absolute;
                top: spacing(1);
                left: spacing(1);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                font-size: $normalFontSize;
                color: $secondFontColor;
                background: $fontColor;
                margin: 0;
                padding: spacing(0.25);
                border-radius: 100px;
                z-index: 2;
            }
            & .loader{
                position: absolute;
                width: 30px !important;
                height: 30px !important;
                color: $fontColor;
            }
            & .delete{
                position: absolute;
                top: spacing(1);
                right: spacing(1);
                padding: spacing(0.25);
                background: $fontColor;
                z-index: 3;
                &:hover{
                    background: rgba($color: #646870, $alpha: 0.9);
                }
                & .icon{
                    width: 18px;
                    padding: 3px;
                    color: $secondFontColor;
                }
            }
            & > .image{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: spacing(1);
                &.blur{
                    filter: blur(2px);
                }
            }
            & > .invalidPicture{
                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                align-items: center;
                justify-content: center;
                flex: 1;
                display: flex;
                flex-direction: row;
                z-index: 99;
                & .invalidBackground{
                    background-color: rgba(255, 255, 255,.9);
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    border-radius: spacing(1);
                    flex: 1;
                    margin: 0 spacing(2) spacing(2) spacing(2);
                    padding: spacing(2) spacing(1.5);
                    & p{
                        text-align: center;
                        font-weight:normal;
                        color: #1A1C21;
                        font-size: $extraExtraSmallFontSize;
                        width: 144px;
                        margin: 0 0 0 spacing(1);
                        text-align: justify;
                        font-weight: 500;
                    }
                    & .closeIcon{
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 860px){
    .fileUploader{
        & .content{
            overflow-y: scroll;
            & .item{
                width: 114px;
                height: 164px;
            }
        }
    }
}