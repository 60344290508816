@import "../../../../../styles/variables.scss";
@import "../../../../../styles/functions.scss";

.timeLineSkeleton{
    padding: 0;
    margin: 0 0 auto;
    & .opposite{
        display: none;
    }
    & .dot,
    & .connector{
        background: $primaryColor;
        color: $primaryColor;
    }
    & .item{ 
        width: 80px;
    }
}
