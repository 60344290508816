@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

@keyframes heightAnimation {
    0% {
        height: 0;
    }
    100% {
        height: auto;
    }
}

.discountLinzerModal{
    position: relative;
    & .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: spacing(2);
        padding: spacing(4) spacing(4) spacing(2);
        min-width: auto;
        min-height: 139px;
        visibility: hidden;
        opacity: 0;
        transition: 0.5s ease-in-out all;
        /* animation: heightAnimation 0.5s; */
        &.show{
            position: relative;
            opacity: 1;
            visibility: visible;
            transition: 0.5s ease-in-out all;
            /* animation: heightAnimation 0.5s; */
        }
        &.absolute{
            position: absolute;
        }
        & .text{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0;
            text-align: center;
        }
        & .message{
            font-size: $smallFontSize;
            font-weight: 400;
            color: $secondFontColor;
            margin: 0 0 spacing(2);
        }
        & .title{
            font-size: $extraSmallFontSize;
            font-weight: 400;
            color: $thirdFontColor;
        }
        & .value{
            font-size: 36px;
            font-weight: 400;
            color: $secondFontColor;
        }
        & .subTitle{
            font-size: $extraSmallFontSize;
            font-weight: 400;
            color: $thirdFontColor;
        }
        & .buttons{
            display: flex;
            align-items: center;
            gap: spacing(4);
            margin: spacing(4) 0 0;
        }
    }
}

.loaderDiscount{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: spacing(1);
    & .loader{
        width: 24px !important;
        height: 24px !important;
        color: $fourBackgroundColor;
    }
    & p{
        font-size: $smallFontSize;
        font-weight: 400;
        color: $thirdFontColor;
        margin: 0;
    }
}
