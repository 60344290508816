@import "../../../../styles/variables.scss";
@import "../../../../styles/functions.scss";
@import "../../../../styles/mixins.scss";
.fakeView{
    flex: .09;
}
@media (max-width: 1480px) {
    .fakeView{
        flex: .07;
    }
}

.myOffersContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: spacing(2);
    & .noOffers{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    & .header{
        & .tabs{
            width: 100%;
            & .selected{
                color: $primaryColor;
            }
            & .sideBarIndicator{
                background-color: $primaryColor;
            }
        }
    }
    & .pagination{
        margin: 0 auto;
    }
    & .myOffers{
        width: 100%;
        height: 100%;
        overflow-y: auto;
        & .noData{
            height: 100%
        }
        & .table{
            @include table;
            & .row{
                cursor: pointer;
                & .ellipsis{
                    
                }
                & .picture{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 60px;
                    & img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: $borderRadius;
                    }
                }
                & .text{
                    text-align: start;
                }
                & .price{
                    white-space: pre;
                }
                & .state{
                    @include chips;
                    font-size: $extraExtraSmallFontSize !important;
                    font-weight: 500;
                    padding: spacing(0.75) spacing(1.25);
                    width: min-content;
                    border-radius: $borderRadius;
                    white-space: pre;
                }
                & .removePadding{
                    padding: 0;
                }
                & .action{
                    position: relative;
                    & .arrow{
                        &.open .icon{
                            transform: rotate(180deg);
                        }
                        & .icon{
                            transform: rotate(0deg);
                            font-size: $mediumFontSize;
                        }
                    }
                }
            }
            & .offer{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: spacing(2) spacing(1);
                cursor: pointer;
                & > .offerContainer{
                    flex: .93;
                    display: flex; 
                    gap: 16px;
                    align-items: center;
                }
                &.hover:hover{
                    background: rgba(0, 0, 0, 0.025);
                }
                & .info{
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    min-width: 120px;
                    margin: 0;
                    font-size: $extraSmallFontSize;
                    gap: spacing(0.25);
                    & > .status{
                        @include chips;
                        font-weight: 500;
                        background: none !important;
                    }
                    & > .price{
                        font-size: $smallFontSize;
                    }
                    & > .date{
                        font-weight: 400;
                        color: $fontColor;
                    }
                }
                & .chip{
                    @include chips;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: $extraExtraSmallFontSize !important;
                    font-weight: 500;
                    padding: spacing(0.75) spacing(1.25);
                    width: min-content;
                    min-width: 80px;
                    border-radius: $borderRadius;
                    white-space: pre;
                    text-transform: uppercase;
                    user-select: none;
                }
                & .action {
                    &.publication{
                        margin: 0 0 0 0;
                    }
                    & .icon{
                        font-size: $mediumFontSize;
                    }
                }
            }
    
        }
    }
}
.actions{
    & > .action{
        width: 36px;
        height: 36px;
        border-radius: 36px;
    }
    display: flex;
    align-items: center;
    flex-direction: row;
}

.loaderOffers{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: spacing(2);
    & .loader{
        width: 28px !important;
        height: 28px !important;
        color: $fontColor;
    }
    & .text{
        font-size: $smallFontSize;
        font-weight: 500;
        color: $fontColor;
        margin: 0;
    }
}

.myPublicationCardCypress{
    position: relative !important;
}
@media screen and (max-width: 1024px){
    .cell{
        & > p{
            font-size: 14px !important; 
        }
    }
    .info{
        & > .price{
            font-size: 14px !important; 
        }
        & > .date{
            font-size: 13px !important; 
        }
    }
    .actions{
        display: flex;
        flex-direction: column-reverse;
    }
}