@import "../../../../../styles/variables.scss";
@import "../../../../../styles/functions.scss";

.informeContainer{
    display: flex;
    flex: 1;
    flex-direction: column;
    & > .cardRow{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        & > p{
            margin: 8px 0;
        }
        & > p:first-child{
            font-weight: 500;
            color: $thirdFontColor;
            font-size: $extraSmallFontSize;
        }
        & > p:last-child{
            font-weight: 400;
            color: $fontColor;
            font-size: $extraSmallFontSize;
        }
    }
}