@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.selectSharedContainer{
    position: relative;
    &.fullWidth{
        width: 100% !important;
    }
    & .label{
        color: $thirdFontColor !important;
    }
    & .select{
        background: none !important;
    }
    & .loader{
        width: 20px;
        height: 20px;
        color: $primaryColor;
    }
    &.text{
        border: none;
        & .arrow{
            top: auto !important;
            right: 8px !important;
            width: 24px;
            color: $thirdFontColor;
        }
        & .selected{
            display: flex;
            align-items: center;
            padding: 0 spacing(4) 0 0 !important;
            background: none !important;
        }
    }
    &.border{
        position: relative;
        display: flex;
        flex-direction: column;
        width: auto;
        cursor: pointer;
        & .selector{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 spacing(2);
            border-bottom: 2px solid $secondBackgroundColor;
            user-select: none;
            & p {
                font-size: $smallFontSize;
                color: $fontColor;
            }
            & .arrow{
                width: 24px;
                color: $thirdFontColor;
            }
            &.open{
                & .arrow{
                    transform: rotate(180deg);
                }
            }
        }
        & .options{
            padding: spacing(3) 0;
            display: none;
            flex-direction: column;
            gap: spacing(1);
            border-left: 1px solid $secondBackgroundColor;
            border-right: 1px solid $secondBackgroundColor;
            border-bottom: 1px solid $secondBackgroundColor;
            border-radius: 0 0 0.5rem 0.5rem;
            &.open{
                display: flex;
            }
            & .option{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                height: auto;
                background: none !important;
                border-radius: 0 !important;
                &:hover{
                    background: $secondBackgroundColor!important;
                }
                & .content{
                    display: flex;
                    align-items: center;
                    gap: spacing(1);
                }
                & .check{
                    width: 24px;
                    color: $fontColor;
                }
                & p{
                    font-size: $smallFontSize !important;
                    margin: 0;
                    text-align: start;
                }
            }
        }
    }
    &.filled{
        position: relative;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        border: 1px solid $thirdFontColor !important;
        border-radius: 4px;
        &:hover{
            background: $fiveBackgroundColor;
        }
        & .loading{
            &.selector{
                padding: spacing(2.25) spacing(2);
            }
        }
        & .selector{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: spacing(2);
            user-select: none;
            height: 100%;
            & p {
                font-size: $extraSmallFontSize;
                color: $thirdFontColor;
                margin: 0;
            }
            &.optionSelected{
                padding: 0 spacing(2);
                height: 56px;
                & p{
                    color: $fontColor;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
            & .data{
                display: flex;
                flex-direction: column;
                gap: 0;
                & .label{
                    font-size: $extraExtraSmallFontSize;
                    font-weight: 400;
                    color: $thirdFontColor;
                    margin: 0 0 spacing(.5) 0;

                }
            }
            & .arrow{
                width: 24px;
                color: $thirdFontColor;
            }
            &.open{
                & .arrow{
                    transform: rotate(180deg);
                }
            }
        }
        & .options{
            position: absolute;
            top: 99%;
            left: -1px;
            right: -1px;
            display: none;
            flex-direction: column;
            gap: spacing(0.5);
            padding: spacing(1.5) 0;
            height: auto;
            max-height: 500%;
            background: $backgroundColor;
            border: 1px solid $thirdFontColor;
            border-radius: 0 0 0.5rem 0.5rem;
            overflow-y: auto;
            z-index: 100;
            &.open{
                display: flex;
            }
            & .option{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: spacing(1) spacing(2);
                width: 100%;
                height: auto;
                background: none !important;
                border-radius: 0 !important;
                &:hover{
                    background: $secondBackgroundColor!important;
                }
                & .content{
                    display: flex;
                    align-items: center;
                    gap: spacing(1);
                }
                & .check{
                    width: 24px;
                    color: $fontColor;
                }
                & p{
                    font-size: $extraSmallFontSize !important;
                    margin: 0;
                    text-align: start;
                }
            }
        }
        & .arrow{
            top: auto !important;
            right: 8px !important;
            width: 24px;
            color: $thirdFontColor;
        }
        & .error{
            position: absolute;
            top: 100%;
            left: 12px;
            font-size: $extraExtraSmallFontSize;
            font-weight: 400;
            color: $errorColor;
            margin: 0 0 0 -12px;
        }
    }
    &.icon{
        & .loader{
            display: flex;
            margin:  auto;
        }
        & .selector{
            display: flex;
            align-items: center;
            gap: spacing(0.5);
            margin: auto;
            cursor: pointer;
            & .iconSelected{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 26px;
                overflow: hidden;
                & p{
                    display: flex;
                    align-items: center;
                    gap: spacing(1);
                    color: $fontColor;
                    margin: 0;
                    & .icon{
                        font-size: 32px;
                    }
                    & .value{
                        font-size: $extraExtraSmallFontSize;
                    }
                }
            }
            & .arrow{
                width: 24px;
                color: $thirdFontColor;
            }
        }
    }
    &.scroll{
        height: 100%;
        & .selectorScroll{
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: spacing(2.5);
            & .options{
                position: relative;
                flex: 1;
                & .loaderOptions{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    gap: spacing(1);
                    & .loader{
                        width: 24px !important;
                        height: 24px !important;
                        color: $primaryColor;
                    }
                    & p{
                        font-size: $smallFontSize;
                        font-weight: 500;
                        color: $fontColor;
                        margin: 0;
                    }
                }
                & .optionsContainer{
                    position: absolute;
                    flex: 1;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 0;
                    overflow-y: auto;
                    & .option{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-bottom: 1px solid $secondBackgroundColor;
                        padding: spacing(2) spacing(1);
                        cursor: pointer;
                        &:hover{
                            background: $secondBackgroundColor;
                        }
                        &:active{
                            background: rgba($color: #000000, $alpha: 0.1);
                        }
                        & p{
                            font-size: $normalFontSize;
                            font-weight: 400;
                            color: $fontColor;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

.skeleton{
    width: 100%;
    height: 56px !important;
}
@media screen and (max-width: 860px){
    .selectSharedContainer{
        &.filled{
            border: 1px solid $fontColor !important;
        }
    }
    
}