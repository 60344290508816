@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.stepperContainer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 12px;
    background: rgba(142, 142, 142, 0.5);
    z-index: 4;
    & .step{
        background: $primaryColor;
        height: 100%;
    }
}