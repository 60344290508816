@import "../../../../../styles/variables.scss";
@import "../../../../../styles/functions.scss";

.modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(5);
    & p{
        font-size: $smallFontSize;
        font-weight: 400;
        color: $fontColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: spacing(2);
        margin: 0;
        text-align: center;
        & .message{
            max-width: 240px;
        }
        & .name{
            font-size: $normalFontSize;
            font-weight: 500;
        }
    }
    & .buttons{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: spacing(2);
    }
}

@media screen and (max-width: 1024px){
    .modal{
        min-width: unset !important;
        & button{
            width: 148px !important;
        }
    }
}