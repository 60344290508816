@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.app {
	min-height: 100vh;
	background-color: $thirdBackgroundColor;
	display: flex;
	flex-direction: column;
	position: relative;
}
.transparency{
	background-color: orange;
}

*::-webkit-scrollbar{
	width: 8px;
	height: 8px;
	background: none;
}
*::-webkit-scrollbar-thumb{
	width: 8px;
	height: 8px;
	background: $thirdFontColor;
	border-radius: 100px;
}