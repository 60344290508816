@import '../../../../../styles/functions.scss';
@import '../../../../../styles/variables.scss';

.modalContainer{
    display: flex;
    flex-direction: column;
    width: 900px;
    transition: width .1s ease-out;
    & .modalChildren{
        padding-left: spacing(4);
        display: flex;
        flex-direction: column;
        & .flexRow{
            display:flex;
            flex-direction: row;
            & .flexColumn{
                display:flex;
                flex-direction: column;
            }
        }
        & .buttonContainer{
            display: flex;
            justify-content: center;
            padding: spacing(2) 0;
        }
    }
    & .fileUploader{
        padding: 0 spacing(2) 0 0;
        & > div{
            padding-left: 0;
        }
        &.fullWidth{
            padding-right: 0;
        }
    }
    & .modalPictureTitle{
        display: flex;
        flex-direction: column;
        padding-bottom: spacing(2);
        & > p{
            margin: 0 0 12px 0;
            text-align: left;
            color: $thirdFontColor;
            width: fit-content;
        }
        & > p:first-child{
            font-weight: 500;
            font-size: $mediumLargeFontSize;
            color: $fontColor;
            margin-top: spacing(1);
        }
        & > p:last-child{
            margin: 0;
            text-align: left;
            color: #4874D0;
            text-decoration: underline;
            font-weight: 500;
            width: fit-content;
        }
        & > p:last-child:hover{
            cursor: pointer;
        }
    }
    .inputsContainer {
        position: relative;
        flex: 1;
        display: flex;
        align-items: stretch;
        margin: 0;
        width: 100%;
        border-radius: 20px;
        &.noImages{
            flex: initial;
            margin: auto;
            width: 256px;
            height: 250px;
        }
        & > .toogleButtonGroup {
            justify-content: center;
            margin: 0 spacing(6);
            & .toogleButton {
                flex: 1;
                max-width: 240px;
            }
        }
        & > .fileUploader {
            align-self: unset;
            & > label > div > div{
                flex-wrap: wrap;
                padding: spacing(2) spacing(2) 0 0;
                overflow-y: auto;
            }
        }
    }
}

.submitButton{
    margin: auto 0 0 !important;
    align-self: center;
    padding-top: spacing(1);
}
.bigModal{
    width: min(100vw, 1400px);
}
.selectedPicture{
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
    border-left: 2px solid #DCE1E5;
    position: relative;
    flex-direction: column;
    & > .pictureContainer{
        padding:spacing(3) spacing(2);
        background-color: $fiveBackgroundColor;
        position: relative;
        & .topContainer{
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            & .bigImage{
                max-height: 596px;
                object-fit: cover;
                max-width: 520px;
            }
        }
        & .index{
            position: absolute;
            top: spacing(2);
            left: spacing(2);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            font-size: $normalFontSize;
            color: $secondFontColor;
            background: $fontColor;
            margin: 0;
            padding: spacing(0.25);
            border-radius: 100px;
            z-index: 2;
        }
        & .cancel{
            position: absolute;
            top: spacing(-1.5);
            right: spacing(-1);
            padding: spacing(0.25);
            background: $fontColor;
            z-index: 3;
            &:hover{
                background: rgba($color: #646870, $alpha: 0.9);
            }
            & .icon{
                width: 18px;
                padding: 3px;
                color: $secondFontColor;
            }
        }
        & .delete{
            position: absolute;
            top: spacing(8);
            left: spacing(2);
            padding: spacing(0.25);
            background: $fontColor;
            z-index: 3;
            &:hover{
                background: rgba($color: #646870, $alpha: 0.9);
            }
            & .icon{
                width: 18px;
                padding: 3px;
                color: $secondFontColor;
            }
        }
        & > .invalidPicture{
            justify-content: center;
            display: flex;
            flex-direction: column;
            z-index: 99;
            flex: 1;
            margin-top: spacing(1);
            & .circularProgress{
                color: black; 
                margin-right: 3px;
            }
            & .invalidBackground{
                background-color: #D2D2D2;
                flex: 1;
                align-items: center;
                display: flex;
                flex-direction: row;
                border-radius: spacing(1);
                margin: 0;
                padding: spacing(1);
                & p{
                    text-align: center;
                    font-weight:normal;
                    color: #1A1C21;
                    font-size: $extraExtraSmallFontSize;
                    margin: 0 0 0 spacing(1);
                    text-align: justify;
                    font-weight: 500;
                    flex: 1;
                }
                & .closeIcon{
                    width: 24px;
                    height: 24px;
                    &.cursorPointer{
                        cursor: pointer;
                    }
                }
            }
        }
    }   
}