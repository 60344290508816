@import "../../../../styles/variables.scss";
@import "../../../../styles/functions.scss";
@import "../../../../styles/mixins.scss";

.edit{
    flex: 1;
    display: flex;
    gap: spacing(0.25);
    padding: 0 spacing(1.25) 0 0;
    & > .linzeAiContainer{
        display: flex;
        flex-direction: column;
        gap: spacing(2.5);
        margin: spacing(5) 0 spacing(1);
        padding: spacing(3) spacing(2);
        width: 280px;
        background: $backgroundColor;
        border-radius: $borderRadius;
        & .item{
            display: flex;
            flex-direction: column;
            gap: spacing(0.5);
            & .title{
                font-size: $smallFontSize;
                font-weight: 600;
                color: $thirdFontColor;
                margin: 0;
            }
            & .content{
                display: flex;
                flex-direction: column;
                gap: spacing(0.5);
                & p{
                    font-size: $extraSmallFontSize;
                    font-weight: 400;
                    color: $thirdFontColor;
                    margin: 0;
                }
            }
        }
    }
    & .reviewContainer{
        display: grid;
        grid-template-columns: 1fr auto;
        grid-auto-flow: row;
        padding: 0 spacing(4) spacing(3);
        & > .data{
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            & p{
                display: flex;
                flex-direction: column;
                gap: spacing(1);
                color: $fontColor;
                & .name{
                    font-size: $mediumFontSize;
                    font-weight: 500;
                    &.skeleton{
                        width: 60%;
                    }
                }
                & .info{
                    font-size: $normalFontSize;
                    font-weight: 400;
                    &.skeleton{
                        width: 35%;
                    }
                }
                & .price{
                    font-size: $largeFontSize;
                    font-weight: 600;
                    &.skeleton{
                        width: 40%;
                    }
                }
                & .oldPrice{
                    font-size: $normalFontSize;
                    font-weight: 400;
                    text-decoration: line-through;
                    &.skeleton{
                        width: 30%;
                    }
                }
            }
        }
        & > .item{
            margin: spacing(3) 0;
            grid-column: 1 / 3;
            display: flex;
            flex-direction: column;
            gap: spacing(4);
            & .title{
                font-size: $mediumFontSize;
                font-weight: 500;
                color: $thirdFontColor;
                margin: 0;
                &.skeleton{
                    width: 130px;
                }
            }
            &.photos .content{
                position: relative;
                display: flex;
                flex-direction: column;
                & .skeletonItems{
                    display: flex;
                    gap: spacing(3);
                    & .skeleton {
                        width: 260px;
                        height: 250px;
                    }
                }
                & .addPhoto{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: spacing(3);
                    width: 260px;
                    height: 240px;
                    border: 2px dashed $thirdFontColor;
                    border-radius: $borderRadius;
                    &:hover{
                        background: rgba($color: #000000, $alpha: 0.05);
                    }
                    &:active{
                        background: rgba($color: #000000, $alpha: 0.1);
                    }
                    & input{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 4;
                        opacity: 0;
                        cursor: pointer;
                    }
                    & img{
                        width: 36px !important;
                        height: auto !important;
                    }
                    & p{
                        font-size: $extraExtraSmallFontSize;
                        font-weight: 400;
                        color: $fontColor;
                        margin: 0;
                    }
                }
                & .image{
                    position: relative;
                    width: 260px !important;
                    height: 250px !important;
                    & .close{
                        position: absolute;
                        top: spacing(1);
                        right: spacing(1);
                        padding: spacing(0.5);
                        background: $fontColor;
                        & .icon{
                            width: 24px;
                            color: $secondFontColor;
                        }
                    }
                    & .picture{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    & .index{
                        position: absolute;
                        top: spacing(1);
                        left: spacing(1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        font-size: $normalFontSize;
                        color: $secondFontColor;
                        background: $fontColor;
                        margin: 0;
                        padding: spacing(0.25);
                        border-radius: 100px;
                        z-index: 2;
                    }
                    & .invalidPic{
                        filter: blur(3px);
                    }
                    & > .invalidPicture{
                        position: absolute;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        align-items: center;
                        justify-content: center;
                        flex: 1;
                        display: flex;
                        flex-direction: row;
                        z-index: 99;
                        & .invalidBackground{
                            background-color: rgba(255, 255, 255,.9);
                            align-items: center;
                            display: flex;
                            flex-direction: row;
                            border-radius: spacing(1);
                            flex: 1;
                            margin: 0 spacing(2) spacing(2) spacing(2);
                            padding: spacing(2) spacing(1.5);
                            & p{
                                text-align: center;
                                font-weight:normal;
                                color: #1A1C21;
                                font-size: $extraExtraSmallFontSize;
                                width: 144px;
                                margin: 0 0 0 spacing(1);
                                text-align: justify;
                                font-weight: 500;
                            }
                            & .closeIcon{
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }
                & .editButton{
                    border: 1px solid $mainFontColor !important;
                    align-self: center;
                    & p{
                        font-weight: 500;
                    }
                    & .editIcon{
                        margin: 0 0 4px 4px;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            & .content{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-auto-flow: row;
                gap: spacing(3) spacing(2.5);
            }
            &.conforts .content{
                gap: spacing(4) spacing(5);
            }
            &.tags .content{
                display: flex;
                flex-direction: column;
                gap: spacing(2);
                & .description{
                    font-size: $extraSmallFontSize;
                    font-weight: 400;
                    color: $fontColor;
                    margin: 0;
                }
                & .skeleton.input{
                    height: 56px;
                }
                & .items{
                    display: flex;
                    flex-wrap: wrap;
                    gap: spacing(2);
                }
            }
            & .invalidContainer{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: -16px;
                & .closeIcon{
                    width: 24px;
                    height: 24px;
                }
                & .textInvalidPictures{
                    display: flex;
                    flex-direction: row;
                    font-weight: 500;
                    font-size: $smallFontSize;
                    margin: 0 0 0 spacing(1);
                    & .numberOfInvalid{
                        display: contents;
                        color: red;
                    }
                }
            }
        }
        &> .description{
            display: flex;
            flex-direction: column;
            margin: 0 0 spacing(3) 0;
            & .title{
                font-size: $mediumFontSize;
                font-weight: 500;
                color: $thirdFontColor;
                margin: 0;
                margin: spacing(3) 0;
                &.skeleton{
                    width: 130px;
                }
            }
            & .skeletonItems{
                display: flex;
                gap: spacing(3);
                & .skeleton {
                    width: 260px;
                    height: 250px;
                }
            }
        }
        & > .buttons{
            position: relative;
            grid-column: 1 / 2;
            display: flex;
            flex-direction: column;
            gap: spacing(2.5);
            margin: 0 auto;
            & .skeleton {
                width: 280px;
                height: 45px;
            }
            & .error{
                font-size: $extraSmallFontSize;
                font-weight: 400;
                color: $errorColor;
                margin: 0;
                text-align: center;
            }
            & .timeOut{
                position: absolute;
                top: 101%;
                left: 0;
                width: 280px;
                transition: 0.25s ease-in-out all;
                opacity: 0;
                &.show{
                    transition: 0.25s ease-in-out all;
                    visibility: visible;
                    opacity: 1;
                }
                & .loader{
                    @include loaderDots;
                }
            }
        }
    }
}
.sliderContent{
    margin-left: 0;
}