@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.inputsContainer {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: wrap;
    gap: spacing(3);
    padding: 0 spacing(1);
    & > .carInformation{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: spacing(2);
        & > p{
            width: 204px;
            font-size: $normalFontSize;
            font-weight: 400;
        }
        & > img{
            height: 64px;
        }
    }
    & > .section{
        display: flex;
        flex-direction: column;
        gap: spacing(2.5);
        & > .title{
            font-size: $mediumFontSize;
            font-weight: 600;
            color: $thirdFontColor;
            margin: 0;
        }
        &.caracteristics{
            & > .content{
                display: flex;
                flex-wrap: wrap;
                gap: spacing(3) spacing(4);
                & .caracteristic{
                    display: flex;
                    align-items: center;
                    gap: spacing(1.5);
                    margin: 0 auto;
                    & img{
                        width: 32px;
                    }
                    & p{
                        display: flex;
                        flex-direction: column;
                        margin: 0;
                        gap: spacing(1);
                        font-size: $extraSmallFontSize;
                        color: $fontColor;
                        & .title{
                            font-weight: 300;
                        }
                        & .value{
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        &.vin{
            & > .content{
                display: flex;
                flex-direction: column;
                align-items: center;
                & img{
                    width: 380px;
                }
                & p{
                    font-size: $normalFontSize;
                    font-weight: 400;
                    color: $fontColor;
                    margin: 0;
                    text-transform: uppercase;
                }
            }
        }
        &.model{
            margin-top: spacing(4);
            width: 100%;
        }
        & .content{
            padding: 0 spacing(2);
        }
    }
    & .bottom{
        margin: auto auto 0;
        & .button{
            margin: spacing(4) auto 0;
        }
    }
}