@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.phoneInput{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: spacing(1) spacing(1.5);
    background: $secondFontColor;
    outline: solid 1px $fontColor;
    outline-offset: -1px;
    border-radius: 4px;
    &.focus{
        outline: solid 2px $fontColor;
        outline-offset: -2px;
    }
    &.loading{
        flex-direction: row;
        padding: spacing(2.5) spacing(1.5);
        justify-content: space-between;
    }
    & .label{
        font-size: $extraSmallFontSize;
        font-weight: 400;
        color: $thirdFontColor;
        margin: 0;
    }
    & .error{
        position: absolute;
        top: 100%;
        left: 12px;
        font-size: $extraExtraSmallFontSize;
        font-weight: 400;
        color: $errorColor;
        margin: 0 0 0 -12px;
    }
    & .loader{
        width: 20px;
        height: 20px;
        color: $primaryColor;
    }
    & .inputs{
        display: flex;
        align-items: center;
        & .codeIconSelector{
            display: flex;
            align-items: center;
            gap: spacing(0.25);
            cursor: pointer;
            & .iconSelected{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20px;
                overflow: hidden;
                & p{
                    display: flex;
                    align-items: center;
                    gap: spacing(1);
                    color: $fontColor;
                    margin: 0;
                    & .icon{
                        font-size: $smallFontSize;
                    }
                    & .value{
                        font-size: $extraSmallFontSize;
                    }
                }
            }
            & .arrow{
                top: auto !important;
                right: 8px !important;
                width: 20px;
                color: $fontColor;
            }
        }
        & .options{
            position: absolute;
            top: 99%;
            left: -1px;
            right: -1px;
            display: none;
            flex-direction: column;
            padding: spacing(1.5) 0;
            height: auto;
            max-height: 300%;
            background: $backgroundColor;
            border: 1px solid $thirdFontColor;
            border-radius: 0 0 0.5rem 0.5rem;
            overflow-y: auto;
            z-index: 100;
            &.open{
                display: flex;
            }
            & .option{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: spacing(1) spacing(2);
                height: auto;
                background: none !important;
                border-radius: 0 !important;
                cursor: pointer;
                &:hover{
                    background: $secondBackgroundColor!important;
                }
                & > .content{
                    display: flex;
                    align-items: center;
                    gap: spacing(1);
                    font-size: $extraSmallFontSize !important;
                    font-weight: 400;
                    color: $fontColor;
                    text-align: start;
                    margin: 0;
                }
            }
        }
        & .numberInput{
            font-size: $extraSmallFontSize;
            font-weight: 400;
            color: $fontColor;
            width: 100%;
            background: none;
            border: none;
            outline: none;
        }
    }
}
