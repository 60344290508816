@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/functions.scss";

.button{
    grid-column: 1 span;
    /* padding: spacing(3.5) 0; */
    width: 100%;
    height: 54px !important;
    grid-row: 2 / 3;
    &.text{
        font-size: $extraLargeFontSize;
        height: auto !important;
    }
    &.large{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
}