@import '../../styles/variables.scss';

.datePicker{
    position: relative;
    & .loader{
        width: 20px;
        height: 20px;
        color: $primaryColor;
    }
    & .error{
        position: absolute;
        top: 100%;
        left: 12px;
        font-size: $extraExtraSmallFontSize;
        font-weight: 400;
        color: $errorColor;
        margin: 0;
    }
}
.renderInput{
    position: relative;
    & .label{
        color: #8E8E8E !important;
    }
    & .input{
        background: none !important;
        border: 1px solid $thirdFontColor;
        border-radius: 4px;
    }
}