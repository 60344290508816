@import "../../../../../styles/variables.scss";
@import "../../../../../styles/functions.scss";

.interested{
    display: flex;
    padding: spacing(2.5) spacing(3);
    & .content{
        flex: 1;
        display: flex;
        align-items: center;
        gap: spacing(2);
        width: 100%;
        & .cardImg{
            margin: 0 0 0 auto;
            width: 70px;
        }
        & .cardLeft{
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: spacing(0.5);
            flex: 1;
            & > .text {
                font-weight: 500;
                margin: 0;
            }
            & > .title{
                font-size: $normalFontSize;
                color: $fontColor;
            }
            & > .value{
                font-size: $extraLargeFontSize;
                color: $fontColor;
                font-weight: 700;
            } 
            & > .period{
                font-size: $extraExtraSmallFontSize;
                color: $thirdFontColor;
            } 
        }
    }
}