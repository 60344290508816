@import "../../styles/variables.scss";
@import "../../styles/functions.scss";
.profileCardLimited{
    height: fit-content;
    & .qrGeneratorContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        & > img{
            margin-bottom: spacing(2);
        }
        & > p{
            margin: spacing(2) 0;
        }
        & > .qrDescription{
            color: $fontColor;
            font-size: $extraSmallFontSize;
            max-width: 312px;
            text-align: center;
        }
        & > .qrCarTitle{
            font-size: $normalFontSize;
            font-weight: 500;
        }
    }
}
