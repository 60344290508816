@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.confortContainer {
    display: flex;
    align-items: center;
    gap: spacing(2);
    cursor: pointer;
    border-radius: 8px;
    &.hover:hover{
        background: rgba($color: #000000, $alpha: 0.05);
    }
    & .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        min-width: auto;
        height: 48px;
        border-radius: 8px;
        background: $secondBackgroundColor;
        &.selected{
            background: $thirdFontColor;
            & img{
                z-index: 2;
            }
        }
        &:hover{
            background: $secondBackgroundColor;
            &.selected{
                background: $thirdFontColor;
            }
        }
    }
    & img{
        width: 1.5rem;
        filter: brightness(0) saturate(100%) invert(22%) sepia(4%) saturate(1318%) hue-rotate(164deg) brightness(90%) contrast(86%);
    }
    & .text{
        font-size: $extraSmallFontSize;
        font-weight: 400;
        color: $fontColor;
        margin: 0;
    }
}

.skeletonContent{
    display: flex;
    align-items: center;
    gap: spacing(3);
    & .skeleton{
        &.image{
            width: 56px;
            height: 56px;
        }
        &.text{
            font-size: $mediumFontSize;
            width: 100px;
        }
    }
}

@media screen and (max-width: 1024px){
    .confortContainer {
        gap: spacing(1.5);
        & .icon{
            width: 45px;
            min-width: auto;
            height: 45px;
            border-radius: 0.5rem;
        }
        & img{
            width: 1.5rem;
        }
        & .text{
            font-size: $extraSmallFontSize;
        }
    }

    .skeletonContent{
        gap: spacing(1.5);
        & .skeleton{
            &.image{
                width: 45px;
                height: 45px;
            }
        }
    }
}