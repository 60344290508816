@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.carImage{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    width: 368px !important;
    height: 536px !important;
}
.cardContainer{
    display: flex;
    flex: 1;
    flex-direction: column;
    & > .noChildren{
        display: flex;
        flex-direction: column;
        margin: 0 spacing(2);
        & > .cardTitleContainer{
            display: flex;
            align-items: center;
            margin: spacing(2) 0 0;
            gap: spacing(2);
            & > .cardTitle{
                font-size: $mediumFontSize;
                margin-right: $defaultPadding;
                max-width: 300px;
                width: 100%;
            }
            & .showDetail{
                width: 20px;
                height: 20px;
            }
        }
        & > .priceTitle{
            font-size: $normalFontSize;
            margin: 0 auto $defaultPadding 0;
            max-width: 100px;
            width: 100%;
        }
        & > .price{
            font-size: $largeFontSize;
            max-width: 200px;
            width: 100%;
            margin: 0 auto 0 0;
        }
    }
    & > .separator{
        width: 100%;
        height: 1px;
        background-color: $thirdFontColor;
        margin: $defaultPadding 0;
    }
    & > .children{
        display: flex;
        flex: 1;
        flex-direction: column;
        margin: 0 spacing(2);
    }
}
.children.right{
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    white-space: normal;
    display: flex;
    * > div{
        height: 100%;
    }
}
.bottomDetail{
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    display: flex;
    flex-direction: column;
    margin-right: spacing(2);
    & > .carImage{
        border-radius: $borderRadius;
        width: 368px;
    }
    & > .cardTitleContainer{
        display: flex;
        align-items: center;
        & > .cardTitle{
            font-size: $mediumFontSize;
            margin-right: $defaultPadding;
            max-width: 300px;
            width: 100%;
        }
        & .showDetail{
            width: 20px;
            height: 20px;
        }
    }
    & > .priceTitle {
        font-size: $normalFontSize;
        color: $fontColor;
        margin: 0 auto $defaultPadding 0;
        max-width: 100px;
        width: 100%;
    }
    & > .price{
        font-size: $largeFontSize;
        color: $fontColor;
        font-weight: 700;
        margin: 0 auto 0 0;
        max-width: 200px;
        width: 100%;
    }
}
.onlyPicture{
    height: 100%;
    &.children{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
    }
}