@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.inputsContainer {
    position: relative;
    display: flex;
    align-items: stretch;
    margin: auto 0;
    width: 320px;
    height: 104px;
    background: #ffffff;
    border-radius: 20px;
    & > .chilePatenteBackground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
    }
    & > .textFieldInput {
        z-index: 5;
        & input {
            margin: -8px 0 0;
            font-size: 60px;
            font-weight: 500;
            color: #484848;
            text-align: center;
        }
    }
}
.carTitle{
    font-size: $largeFontSize !important;
    font-weight: 700 !important;
    margin: spacing(4) 0;
}
.modalText{
    text-align: center;
    font-size: $normalFontSize;
    margin: 0 0 spacing(2) !important;
}
.marginBottom{
    margin-bottom: spacing(12) !important;
}
.submitButton{
    margin: auto 0 0 !important;
}
.modalContainerValidationMail{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: spacing(4) 0 0;
    & .message{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: spacing(2.5);
        text-align: center;
        color: $fontColor;
        margin: 0 0 spacing(4);
        & .title{
            font-size: $largeFontSize;
        }
        & .description{
            font-size: $normalFontSize;
        }
    }
    & .buttonBack p{
        color: $fontColor !important;
    }
}

.modalPlateSearch{
    & > .loaderPlateSearch{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: spacing(5);
        padding: spacing(8) 0 spacing(12);
        height: 100%;
        & .loader{
            width: 60px !important;
            height: 60px !important;
            color: $primaryColor;
        }
        & p{
            position: relative;
            font-size: $normalFontSize;
            font-weight: 400;
            color: $fontColor;
            margin: 0;
            & .timeOut{
                position: absolute;
                top: 10%;
                right: 0;
                left: 0;
                text-align: center;
                margin: spacing(2) auto 0;
                transition: 0.75s ease-in-out all;
                visibility: hidden;
                opacity: 0;
                &.show{
                    top: 90%;
                    transition: 0.75s ease-in-out all;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
    & > .plateFailure{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: spacing(2.5);
        padding: spacing(6) 0 spacing(4);
        & > .image{
            margin: 0 0 spacing(2);
            width: 76px;
        }
        & > p{
            color: $fontColor;
            margin: 0;
            text-align: center;
            &.title{
                font-size: $mediumFontSize;
                font-weight: 500;
            }
            &.subTitle{
                font-size: $smallFontSize;
                font-weight: 400;
            }
        }
        & .button{
            width: 320px !important;
            margin: 1rem 0 0 0;
            
        }
        & .offer{
            border: 0.125rem solid #7100FF !important;
        }
        & .buy{
            background: #7100FF !important;
            &:hover{
                background: rgba($color: #7100FF, $alpha: 0.9) !important;
            }
        }
    }
    & > .plateSuccess{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: spacing(2.5);
        padding: spacing(6) 0 spacing(4);
        & > .image{
            margin: 0 0 spacing(2);
            width: 76px;
        }
        & > p{
            color: $fontColor;
            margin: 0;
            text-align: center;
            &.subTitle{
                font-size: $mediumFontSize;
                font-weight: 500;
            }
            &.carName{
                font-size: $largeFontSize;
                font-weight: 400;
            }
        }
        & .button{
            margin: spacing(2) 0 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .inputsContainer {
        width: unset;
    }
}