@import "../../../../../styles/variables.scss";
@import "../../../../../styles/functions.scss";

.qr{
    display: flex;
    padding: spacing(2.5) spacing(3);
    & .container{
        display: flex;
        align-items: center;
        gap: spacing(2);
        & .code{
            width: 90px;
            height: 90px;
        }
        & .content{
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: spacing(0.5);
            & > .text {
                font-size: $normalFontSize;
                font-weight: 400;
                color: $fontColor;
                margin: 0;
            }
            & > .title {
                font-size: $normalFontSize;
                font-weight: 500;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            & > .description {
                font-size: $smallFontSize;
                font-weight: 400;
            }
            & > .price {
                font-size: $mediumFontSize;
                font-weight: 700;
            }
        }
    }
}