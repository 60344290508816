@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.buyModal{
    position: relative;
    display: flex;
    flex: 1;
    justify-content: end;
    padding: spacing(4) spacing(12) spacing(4) 0;
    background: url("../../assets/images/background_buy.webp") no-repeat center center;
    background-size: auto 1000px;
    background-position: top;
    & > .buyModalContainer{
        position: relative;
        display: flex;
        flex-direction: column;
        top: 0;
        bottom: 0;
        padding: spacing(2.5) spacing(4.5) spacing(6);
        max-width: 740px;
        width: 100%;
        background-color: $backgroundColor;
        border-radius: $borderRadius;
        & > .headerModal{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 spacing(4);
            width: 100%;
            & .backButton{
                & .icon{
                    width: 32px;
                    color: $fontColor;
                }
            }
            & .title{
                font-size: $mediumFontSize;
                font-weight: 500;
                color: $fontColor;
                margin: 0;
            }
            & > .stepperNumber{
                font-size: $normalFontSize;
                font-weight: 400;
                color: $thirdFontColor;
                margin: 0;
            }
        }
        & > .buyBody{
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
        }
    }
}