@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.inputsContainer {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
    flex-wrap: wrap;
    gap: spacing(4) spacing(2);
    margin: 0 0 spacing(4);
    width: 520px;
}

.submitButton{
    margin: auto 0 0 !important;
}