@import '../../styles/variables.scss';

.breadCrumbs{
    font-size: $smallFontSize;
    font-size: 400;
    color: $fontColor;
    & .option{
        margin: 0;
        &:hover{
            text-decoration: underline;
        }
        &.bold{
            font-weight: 600;
        }
    }
}