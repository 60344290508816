@import "../../../../styles/variables.scss";
@import "../../../../styles/functions.scss";
@import "../../../../styles/mixins.scss";

.profileCardContainer{
    display: flex !important;
    padding-top: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 !important;
    background: none !important;
    & > .managementContainer{
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        grid-auto-flow: row;
        grid-auto-rows: auto 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: spacing(1);
        flex: 1;
        & .textNoData{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $smallFontSize;
            font-weight: 500;
            color: $thirdFontColor;
            text-align: center;
        }
        & > .card{
            display: flex;
            flex-direction: column;
            align-items: start;
            padding: spacing(2.5) spacing(3);
            gap: spacing(1);
            background: $backgroundColor;
            border-radius: $borderRadius;
            & > .header{
                display: flex;
                align-items: center;
                gap: spacing(1);
                & .title {
                    font-size: $mediumFontSize;
                    font-weight: 500;
                    color: $fontColor;
                    margin: 0;
                }
            }
            & > .content{
                flex: 1;
                display: flex;
                width: 100%;
            }
        }
        & .qr{
            grid-column: 1 / 4;
            grid-row: 1 / 2;
        }
        & .history{
            grid-column: 1 / 7;
            grid-row: 2 / 8;
            position: relative;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            & > .historyCardTitle {
                font-size: $mediumFontSize;
                font-weight: 500;
                color: $fontColor;
                margin: 0;
                margin: 0 0 spacing(1);
            }
            & .content{
                max-height: 440px;
                overflow-y: auto;
            }
        }
        & .inspection{
            grid-column: 4 / 7;
            grid-row: 1 / 2;
            & .content{
                position: relative;
                display: flex;
                flex-direction: column;
            }
        }
        & .offers{
            grid-column: 7 / 10;
            grid-row: 1 / 4;
            & .content{
                flex: 1;
                flex-direction: column;
                gap: spacing(1);
                padding: 0 spacing(2) 0 0;
                max-height: 245px;
                overflow-y: auto;
                & > .item{
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: spacing(1);
                    cursor: pointer;
                    &.hover:hover{
                        background: rgba(0, 0, 0, 0.025);
                    }
                    & > .info{
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                        margin: 0;
                        font-size: $extraSmallFontSize;
                        gap: spacing(0.25);
                        & > .status{
                            @include chips;
                            font-weight: 500;
                            background: none !important;
                        }
                        & > .price{
                            font-size: $smallFontSize;
                        }
                        & > .date{
                            font-weight: 400;
                            color: $fontColor;
                        }
                    }
                    & > .button{
                        display: flex;
                        align-items: center;
                        gap: spacing(1);
                        font-weight: 500;
                        font-size: $smallFontSize;
                        color: $fontColor;
                        margin: 0;
                        cursor: pointer;
                        user-select: none;
                        text-decoration: underline;
                        &.blue{
                            color: $primaryColor;
                        }
                        & .icon{
                            font-size: $normalFontSize;
                            color: $primaryColor;
                        }
                    }
                    & > .chip{
                        @include chips;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: $extraExtraSmallFontSize !important;
                        font-weight: 500;
                        margin: 0;
                        padding: spacing(0.75) spacing(1.25);
                        width: min-content;
                        min-width: 80px;
                        border-radius: $borderRadius;
                        white-space: pre;
                        text-transform: uppercase;
                        user-select: none;
                    }
                }
            }
        }
        & .payments{
            grid-column: 7 / 10;
            grid-row: 4 / 8;
        }
    }
}

@media screen and (min-height: 784px){
    .profileCardContainer{
        & > .managementContainer{
            & > .history{
                & .content{
                    max-height: 600px;
                }
            }
            & > .offers{
                & .content{
                    max-height: 280px;
                }
            }
        }
    }
}