@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.suspenseLoader{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: spacing(2);
    width: 100%;
    height: 100vh;
    background: $backgroundColor;
    & .logo{
        width: 60px;
    }
    & .text{
        width: 120px;
    }
    & .loader{
        width: 120px;
        background-color: $fourBackgroundColor;
        & .barColor{
            background-color: #7100FF;
        }
    }
}