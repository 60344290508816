@import '../../../../../styles/functions.scss';

.skeletonContainer{
    display: flex;
    flex-direction: column;
    gap: spacing(0.5);
    & .title{
        font-size: $mediumFontSize;
        width: 40%;
    }
    & .content{
        display: flex;
        flex-direction: column;
        gap: spacing(0.5);
        & .item{
            font-size: $mediumFontSize;
            width: 100%;
        }
    }
}