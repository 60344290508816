@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.comparaContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & > .comparaButton {
        display: flex;
        background-color: #103AD8;
        padding: .75rem;
        border-radius: .5rem;
    }
}
.comparaImg {
    padding: 0;
    object-fit: contain;
    width: 91.885px;
    height: 19.225px;
    flex-shrink: 0;
    margin-top: .1rem
}
.comparaTitle {
    font-size: $normalFontSize;
    color: $secondFontColor;
    margin: 0;
    margin-right: .25rem;
    user-select: none;
    cursor: default;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox antigua */
    -ms-user-select: none; /* Internet Explorer/Edge */
}



.container{
    padding: spacing(5) 0;
    margin: 0 auto;
    width: 100%;
    &.myPublication{
        padding: 0;
    }
    & .item{
        & > .title{
            font-size: $mediumFontSize;
            font-weight: 600;
            color: $thirdFontColor;
            margin: 0;
        }
    }
    & .border{
        padding: spacing(2) spacing(3);
        border: 1px solid $borderColor;
        border-radius: $borderRadius;
    }
    & .sold{
        margin: 0;
        padding: spacing(0.75) spacing(2);
        font-size: $smallFontSize;
        font-weight: 600;
        text-align: center;
        color: $secondFontColor;
        background: #F70000;
        border-radius: $borderRadius;
        letter-spacing: spacing(0.35);
        text-transform: uppercase;
    }
    & .grid{
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr minmax(auto, 400px);
        grid-auto-rows: auto 1fr auto auto auto;
        grid-auto-flow: row;
        gap: spacing(3);
        margin: 0 auto;
        padding: spacing(8) spacing(8) spacing(10);
        max-width: 1130px;
        background: $backgroundColor;
        border-radius: $borderRadius;
        border: 1px solid rgba(0, 0, 0, 0.12);
        & > .slider{
            position: relative;
            grid-column: 1 / 3;
            grid-row: 1 / 3;
            max-width: 720px;
            width: 100%;
            height: 548px;
            & .paused{
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
            }
        }
        & > .information{
            position: relative;
            grid-column: 3 / 4;
            grid-row: 1 / 2;
            display: grid;
            grid-template-columns: 1fr auto auto;
            grid-auto-rows: min-content;
            grid-auto-flow: row;
            align-items: flex-start;
            gap: spacing(1);
            max-width: 320px;
            & > *{
                margin: 0;
            }
            & .title{
                grid-column: 1 / 4;
                grid-row: 2 / 3;
                font-size: $mediumLargeFontSize;
                font-weight: 400;
                margin: spacing(-1) 0 0;
            }
            & .version{
                grid-column: 1 / 4;
                font-size: $mediumFontSize;
                font-weight: 400;
            }
            & .description{
                grid-column: 1 / 2;
                grid-row: 1 / 2;
                font-size: $normalFontSize;
                font-weight: 300;
                margin: auto 0;
            }
            & > .price{
                grid-column: 1 / 4;
                font-size: $extraLargeFontSize;
                font-weight: 600;
                margin: 0 0 spacing(1);
            }
            & .share{
                grid-column: 2 / 3;
                grid-row: 1 / 2;
                margin: auto 0;
            }
            & .favorite{
                grid-column: 3 / 4;
                grid-row: 1 / 2;
                margin: auto 0;
            }
            & .sold{
                grid-column: 1 / 4;
                z-index: 4;
            }
            & .children{
                grid-column: 1 / 5;
                display: flex;
                flex-direction: column;
                gap: spacing(2);
            }
        }
        & > .certifiedFinancing{
            grid-column: 1 / 3;
            grid-row: 3 / 4;
            display: flex;
            align-items: center;
            gap: spacing(3);
            & > .item{
                display: flex;
                align-items: center;
                gap: spacing(1);
                padding: spacing(0.75) spacing(2.5);
                width: min-content;
                background: #E7D5FF;
                border-radius: $borderRadius;
                & img{
                    width: 22px;
                }
                & p{
                    font-size: $smallFontSize;
                    font-weight: 400;
                    color: #7100FF;
                    margin: 0;
                    white-space: pre;
                }
            }
        }
        & > .responsiveInformation{
            display: none;
            grid-template-columns: repeat(2, 1fr);
            gap: spacing(2.5) spacing(1.5);
            grid-auto-flow: row;
            padding: 0 spacing(3);
            & .sold{
                grid-column: 1 / 3;
            }
            & .buttons{
                display: flex;
                align-items: center;
                gap: spacing(1);
                margin: 0 0 spacing(-1) spacing(-1);
            }
            & .price{
                grid-column: 1 / 3;
                font-size: $extraLargeFontSize;
                font-weight: 600;
                margin: 0;
            }
            & .divider{
                grid-column: 1 / 3;
            }
        }
        & > .description{
            position: relative;
            grid-column: 1 / 4;
            display: flex;
            flex-direction: column;
            gap: spacing(2);
            & p{
                margin: 0;
                color: $fontColor;
            }
            & .titleGPT{
                color: #111111;
                font-size: $mediumLargeFontSize;
                font-weight: 500;
            }
            & .title{
                color: #111111;
                font-size: $normalFontSize;
                font-weight: 500;
            }
            & .content{
                font-size: $normalFontSize;
                line-height: 24px;
                font-weight: 300;
            }
        }
        & > .detail{
            position: relative;
            grid-column: 3 / 4;
            grid-row: 2 / 3;
            display: flex;
            flex-direction: column;
            gap: spacing(2);
            margin: spacing(1) 0 0;
            padding: 0;
            & li{
                display: flex;
                align-items: center;
                gap: spacing(2.5);
                font-size: $smallFontSize;
                margin: 0;
                color: $fontColor;
                list-style: none;
                & img{
                    width: 18px;
                }
            }
        }
        & > .table{
            grid-column: 1 / 4;
            display: flex;
            flex-direction: column;
            gap: spacing(3);
            margin: spacing(4) 0 0;
            // & p {
                // font-size: $mediumFontSize;
                // font-weight: 400;
                // color: $fontColor;
                // margin: 0;
                // min-height: 24px;
            // }
            & > .title{
                font-size: $mediumFontSize;
                font-weight: 400;
                color: $fontColor;
                margin: 0;
                min-height: 24px;
            }
            & table{
                flex: 1;
                width: 100%;
            }
            & .item{
                flex: 1;
                display: flex;
                align-items: center;
                margin: 0;
                font-size: $smallFontSize;
                color: $fontColor;
                &:nth-child(odd){
                    background: #EBEBEB;
                }
                & .noData{
                    column-span: all;
                    width: 100% !important;
                    text-align: center;
                }
                & .name,
                & .value{
                    display: table-cell;
                    padding: spacing(2) spacing(4);
                    text-align: left;
                }
                & .icon{
                    display: table-cell;
                    padding: spacing(2) spacing(2);
                    & img{
                        width: 18px;
                        height: 18px;
                        object-fit: contain;
                        display: flex;
                        margin: auto 0;
                    }
                }
                & .name{
                    flex: 0.5;
                    font-weight: 500;
                }
                & .value{
                    flex: 0.5;
                    font-weight: 400;
                    & .skeleton{
                        font-size: $mediumFontSize;
                        width: 35%;
                    }
                }
            }
        }
    }
    & .similars{
        display: flex;
        flex-direction: column;
        gap: spacing(2);
        margin: spacing(5) auto 0;
        max-width: 1258px;
        width: 100%;
        & .content{
            position: relative;
            align-items: center;
            gap: spacing(2.5) spacing(3);
            width: 100%;
        }
    }
}

@media screen and (max-width: 1240px){
    .container{
        & .grid{
            padding: spacing(6) spacing(4);
        }
    }
}

@media screen and (max-width: 1024px){
    .table{
        gap: 0px !important;
    }
    .comparaContainer{
        flex-direction: column-reverse;
        align-items: unset;
        & > .comparaButton{
            justify-content: center;
            & > .comparaTitle{
                font-size: 14px
            }
            & > .comparaImg{
                width: unset;
                height: 14.225px;
            }
        }

    }
    .container{
        padding: 0;
        &.comparaTitle {

        }
        &.myPublication{
            padding: 0;
            & .grid{
                padding: spacing(11) spacing(8) spacing(5);
                gap: spacing(7);
            }
        }
        & .item{
            & > .title{
                font-size: $mediumFontSize;
                font-weight: 600;
                color: $thirdFontColor;
                margin: 0;
            }
        }
        & .border{
            padding: spacing(2) spacing(3);
            border: 1px solid $borderColor;
            border-radius: $borderRadius;
        }
        & .grid{
            display: flex;
            flex-direction: column;
            gap: 0;
            padding: 0;
            border-radius: 0;
            border: none;
            & > .slider{
                order: 3;
                max-width: 100%;
                height: 555px;
            }
            & > .information{
                order: 1;
                padding: spacing(2) spacing(3);
                &.notPaddingBottom{
                    padding: spacing(2) spacing(3) 0;
                }
                &.loader{
                    padding: spacing(2) spacing(3);
                }
                & > .title{
                    font-size: $mediumFontSize;
                    margin: 0;
                }
                & > .version{
                    font-size: $smallFontSize;
                }
                & > .description{
                    font-size: $extraSmallFontSize;
                }
                & .certified{
                    & p{
                        font-size: $extraSmallFontSize;
                    }
                    & img{
                        width: 16px;
                    }
                }
                & > .price,
                & .share,
                & .favorite,
                & .children,
                & .sold{
                    display: none;
                }
            }
            & > .certifiedFinancing{
                order: 2;
                flex-direction: column;
                align-items: start;
                gap: spacing(1);
                padding: spacing(1) spacing(3) spacing(2);
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                z-index: 5;
                & > .item{
                    padding: 0;
                    background: none;
                    & p{
                        font-size: $extraSmallFontSize;
                    }
                }
                & > .financingBonus{
                    width: min-content;
                    flex-direction: row-reverse;
                }
            }
            & > .buttons{
                order: 4;
                padding: spacing(1) spacing(3);
                gap: 0;
                flex-direction: row-reverse;
                margin: 0 auto 0 spacing(-1);
            }
            & > .responsiveInformation{
                order: 5;
                display: grid;
            }
            & > .detail{
                order: 6;
                padding: spacing(2) spacing(3) 0;
                margin: 0;
                & li{
                    font-size: $extraSmallFontSize;
                    gap: spacing(1);
                    & img{
                        width: 16px;
                    }
                }
            }
            & > .divider{
                order: 7;
                margin: spacing(2) spacing(3);
            }
            & > .description{
                position: relative;
                padding: spacing(1) spacing(3);
                order: 8;
                & .title{
                    font-size: $smallFontSize;
                }
                & .content{
                    font-size: $extraSmallFontSize;
                }
            }
            & > .table{
                order: 9;
                margin: spacing(2) 0 0;
                padding: spacing(1) spacing(3);
                & > .title{
                    font-size: $smallFontSize;
                    min-height: initial;
                }
                & .item{
                    font-size: $extraExtraSmallFontSize;
                    & .name,
                    & .value{
                        padding: spacing(2);
                    }
                    & .name{
                        flex: 0.6;
                        font-weight: 500;
                    }
                    & .value{
                        flex: 0.4;
                    }
                }
            }
        }
        & .similars{
            display: none;
        }
    }
}