@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.sidebar {
    padding: spacing(1) spacing(4) spacing(8) !important;
}

.sidebarContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: spacing(2);
    padding: spacing(6) spacing(2) 0;
    width: 400px;

    & p {
        margin: 0;
    }

    & .title {
        font-size: $extraLargeFontSize;
        font-weight: 600;
        color: $fontColor;
    }

    & .description {
        font-size: $smallFontSize;
        font-weight: 400;
        color: $thirdFontColor;
    }

    & .vehicle {
        display: flex;
        align-items: center;
        gap: spacing(1);

        & p {
            font-size: $mediumLargeFontSize;
            font-weight: 600;
            color: $fontColor;
        }
    }

    & .timeline {
        margin: spacing(1) 0 0;
        padding: 0;
    }

    & .grid {
        gap: spacing(1) spacing(2);
        margin: auto auto 0;

        & .button {
            flex-direction: column;
            display: flex;

            & .counterOffer {
                padding: spacing(1.5) spacing(1);
                width: 100%;

                & p {
                    text-decoration: underline;
                }
            }

            & div {
                flex-direction: row;
                display: flex;

                & .reject {
                    margin: spacing(1) spacing(1);
                }
                & .accept {
                    margin: spacing(1) spacing(1);
                }
            }
        }
    }

    & .input {
        margin: spacing(8) 0 spacing(4);
    }

    & .text {
        display: flex;
        gap: spacing(0.5);
        font-size: $smallFontSize;
        color: $thirdFontColor;
        text-align: center;
        margin: 0 auto;

        &.important {
            margin: auto auto 0;
        }
    }

    & .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: spacing(2);
        margin: spacing(1) auto 0;
    }
}