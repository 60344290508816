@import "../../../../styles/variables.scss";
@import "../../../../styles/functions.scss";

.modalContainer{
    display: flex;
    flex-direction: column;
    gap: spacing(1);
    & p{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: spacing(2);
        margin: 0;
        font-size: $normalFontSize;
        color: $fontColor;
        text-align: center;
        & .message{
            font-weight: 400;
            max-width: 360px;
        }
        & .name{
            font-weight: 500;
        }
    }
    & .buttons{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: spacing(2);
        margin: spacing(3) 0 0;
    }
}