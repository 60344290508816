@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.inputsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    border-radius: 20px;
    & > .inputContent{
        display: flex;
        align-items: normal;
        width: 100%;
        gap: 0;
        & .input{
            border-radius: 100px;
        }
        & .button{
            padding: spacing(0.5);
            width: 56px;
            height: 56px;
            background: $thirdBackgroundColor;
            border-top: 1px solid $fontColor;
            border-right: 1px solid $fontColor;
            border-bottom: 1px solid $fontColor;
            border-radius: 4px;
            border-left: none;
            cursor: pointer;
            &:hover{
                background: rgba($color: #DCE1E5, $alpha: 0.75);
            }
            &:active{
                background: rgba($color: #DCE1E5, $alpha: 0.5);
            }
            &.disabled{
                background: $secondBackgroundColor;
                border-top: 1px solid $fontColor;
                border-right: 1px solid $fontColor;
                border-bottom: 1px solid $fontColor;
                & .icon{
                    color: $thirdFontColor;
                }
            }
            & .icon{
                font-size: 40px;
                color: $secondFontColor;
            }
        }
    }
    & > .tagsContainer{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: spacing(2);
        margin: spacing(2) 0 0;
    }
}

.submitButton{
    margin: spacing(4) 0 0;
}