@import "./styles/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Importar fuentes */
@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-Regular.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-Thin.otf") format("opentype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-ExtraLight.otf") format("opentype");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-Light.otf") format("opentype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-Medium.otf") format("opentype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-SemiBold.otf") format("opentype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-Bold.otf") format("opentype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-ExtraBold.otf") format("opentype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-Black.otf") format("opentype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-ExtraBlack.otf") format("opentype");
	font-weight: 950;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-Hairline.otf") format("opentype");
	font-weight: 100;
	font-style: normal;
}

/* Importar fuentes */
@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-Regular.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-Thin.otf") format("opentype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-ExtraLight.otf") format("opentype");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-Light.otf") format("opentype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-Medium.otf") format("opentype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-SemiBold.otf") format("opentype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-Bold.otf") format("opentype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-ExtraBold.otf") format("opentype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-Black.otf") format("opentype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-ExtraBlack.otf") format("opentype");
	font-weight: 950;
	font-style: normal;
}

@font-face {
	font-family: "LTSaeada";
	src: url("./fonts/LTSaeada-Hairline.otf") format("opentype");
	font-weight: 100;
	font-style: normal;
}

body {
	margin: 0;
	font-family: "LTSaeada", "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
a:link,
a:visited,
a:hover,
a:active {
	text-decoration: none;
	color: $secondFontColor;
}
p{
	color: $fontColor;
}