@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.inspectionContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: spacing(4);
    margin: spacing(2.5) auto 0 0;
    width: 100%;
    & .skeleton{
        height: 20px;
    }
    & > .title{
        font-size: $normalFontSize;
        font-weight: 600;
        color: $thirdFontColor;
        margin: 0;
        &.skeleton{
            width: 25%;
        }
    }
    & > .item {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 0;
        gap: spacing(1);
        width: 100%;
    }
    & .label{
        font-size: $extraSmallFontSize;
        font-weight: 500;
        color: $thirdFontColor;
        margin: 0;
        &.skeleton{
            width: 20%;
        }
    }
    & .value{
        font-size: $extraSmallFontSize;
        font-weight: 400;
        color: $fontColor;
        &.skeleton{
            width: 10%;
        }
    }
    & > .status{
        display: flex;
        align-items: center;
        gap: $defaultPadding;
        width: 100%;
        & > .value {
            font-size: $normalFontSize;
            font-weight: 500;
            color: $primaryColor;
            margin: 0;
            text-transform: uppercase;
            &.skeleton{
                width: 25%;
            }
        }
        & > .eye{
            & img{
                width: 24px;
                filter: invert(39%) sepia(85%) saturate(589%) hue-rotate(186deg) brightness(92%) contrast(83%);
            }
            &.skeleton{
                width: 24px;
                height: 24px;
            }
        }
    }

}