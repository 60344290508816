@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.container{
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    padding: spacing(3);
    & h1{
        text-align: center;
    }
}