@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

@keyframes heightAnimation {
    0% {
        height: 0;
    }
    100% {
        height: auto;
    }
}

.inspectionApprovalModal{
    position: relative;
    & .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: spacing(2);
        padding: spacing(4) spacing(4) spacing(2);
        min-width: auto;
        min-height: 150px;
        visibility: hidden;
        opacity: 0;
        transition: 0.5s ease-in-out all;
        &.show{
            position: relative;
            opacity: 1;
            visibility: visible;
            transition: 0.5s ease-in-out all;
        }
        &.absolute{
            position: absolute;
        }
        & img{
            width: 70px;
        }
        & .text{
            font-size: $smallFontSize;
            font-weight: 400;
            text-align: center;
            color: $secondFontColor;
            margin: spacing(2) 0 spacing(4);
        }
        & .buttons{
            display: flex;
            align-items: center;
            gap: spacing(4);
            margin: auto 0 0;
        }
    }
}

.loaderInspection{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: spacing(1);
    & .loader{
        width: 24px !important;
        height: 24px !important;
        color: $fourBackgroundColor;
    }
    & p{
        font-size: $smallFontSize;
        font-weight: 400;
        color: $thirdFontColor;
        margin: 0;
    }
}
