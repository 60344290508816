@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.sellModal{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-auto-flow: row;
}

.inputsContainer {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: spacing(1) 0 0;
    width: 100%;
    border-radius: 20px;
    & .input{
        max-width: 450px;
        width: 100%;
    }
}