@import './variables.scss';

@function spacing($value){ 
    @return calc($value * $defaultPadding);
}

@keyframes rotating {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
}

@function rotateAnimation($time){
    @return rotating $time linear infinite;
}