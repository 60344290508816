@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.notificationsContainer {
    position: relative;
    padding: spacing(2) 0 spacing(3);
    & > .emptyLoader{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        & img{
            width: 264px;
            animation: rotateAnimation(2s);
        }
    }
    & > .loader{
        position: absolute;
        top: spacing(0.5);
        width: 18px !important;
        height: 18px !important;
        color: $primaryColor;
    }
    & > .showMore{
        position: absolute;
        display: flex;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        & p{
            font-size: $extraSmallFontSize;
            font-weight: 500;
            color: $primaryColor;
            margin: 0 auto;
            text-align: center;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    & > .emptyNotifications{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: spacing(6);
        height: 100%;
        & img{
            width: 264px;
        }
        & p{
            display: flex;
            flex-direction: column;
            gap: spacing(3);
            color: $fontColor;
            text-align: center;
            & .title{
                font-size: $normalFontSize;
                font-weight: 400;
            }
            & .description{
                font-size: $extraSmallFontSize;
                font-weight: 300;
                max-width: 288px;
            }
        }
    }
    & > .scroll{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        & .item{
            display: grid;
            grid-template-columns: auto 1fr;
            grid-auto-flow: row;
            grid-auto-rows: auto 1fr;
            align-items: flex-start;
            gap: spacing(1);
            padding: spacing(2);
            border-bottom: 1px solid $secondBackgroundColor;
            border-radius: $borderRadius;
            cursor: pointer;
            &:hover{
                background: rgba($color: #000000, $alpha: 0.05);
            }
            &:active{
                background: rgba($color: #000000, $alpha: 0.1);
            }
            &.readed .itemContainer{
                & .title,
                & .description{
                    color: $thirdFontColor !important;
                }
            }
            & img{
                grid-column: 1 / 2;
                grid-row: 1 / 2;
                width: 28px;
            }
            & .loader{
                grid-column: 1 / 2;
                grid-row: 2 / 3;
                margin: 0 auto;
                width: 20px !important;
                height: 20px !important;
                color: $thirdBackgroundColor;
            }
            & .itemContainer{
                grid-column: 2 / 3;
                grid-row: 1 / 3;
                display: flex;
                flex-direction: column;
                gap: spacing(1);
                & p{
                    margin: 0;
                    text-align: start;
                }
                & .title{
                    font-size: $extraSmallFontSize;
                    font-weight: 600;
                    color: $fontColor;
                }
                & .description{
                    font-size: $extraSmallFontSize;
                    font-weight: 400;
                    color: $fontColor;
                }
                & .date{
                    font-size: $extraExtraSmallFontSize;
                    font-weight: 500;
                    color: $thirdFontColor;
                }
            }
        }
    }
}