@import "../../../../../styles/variables.scss";
@import "../../../../../styles/functions.scss";

.container {
    position: relative;
    display: grid;
    grid-template-columns: 2fr auto 1fr;
    grid-auto-rows: auto auto 1fr;
    min-height: 480px;
    & > .line{
        background: $secondBackgroundColor;
        &.verticalLine{
            grid-column: 2 / 3;
            grid-row: 3 / 4;
            width: 1px;
            height: 100%;
        }
        &.horizontalLine{
            grid-column: 1 / 4;
            grid-row: 2 / 3;
            width: 100%;
            height: 1px;
        }
    }
    & > .title{
        grid-column: 1 / 4;
        grid-row: 1 / 2;
        font-size: $mediumLargeFontSize;
        font-weight: 500;
        color: $fontColor;
        margin: 0;
        padding: spacing(2) spacing(3);
    };
    & .timeline{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        min-width: 406px;
        padding: spacing(2) spacing(3) spacing(4);
    }
    & .form{
        grid-column: 3 / 4;
        grid-row: 3 / 4;
        display: flex;
        flex-direction: column;
        gap: spacing(2);
        min-width: 346px;
        & > .publication{
            display: flex;
            flex-direction: column;
            font-size: $smallFontSize;
            font-weight: 500;
            color: $fontColor;
            margin: 0;
            gap: spacing(0.5);
            padding: spacing(2) spacing(3) 0 spacing(2);
            & .name{
                font-size: $mediumFontSize;
            }
            & .description{
                font-weight: 400;
            }
            & .price{
                font-weight: 600;
            }
        }
        & > .buttons{
            display: grid;
            margin: auto 0 0;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-flow: row;
            gap: spacing(1) spacing(2);
            padding: spacing(2) spacing(3) spacing(4) spacing(2);
            &.open{
                display: flex;
                flex-direction: column;
                gap: 0;
                height: 100%;
                padding: 0;
                & .line{
                    width: 100%;
                    height: 1px;
                    background: $secondBackgroundColor;
                }
                & > .counterOfferContent{
                    display: flex;
                }    
                & .button{
                    display: none;
                }
            }
            & > .counterOfferContent{
                flex: 1;
                display: none;
                flex-direction: column;
                gap: spacing(2);
                padding: spacing(2) spacing(3) spacing(4) spacing(2);
                & .header{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    & p{
                        font-size: $smallFontSize;
                        font-weight: 500;
                        color: $fontColor;
                        text-decoration: underline;
                        margin: 0;
                    }
                    & .close{
                        & .icon{
                            width: $mediumFontSize;
                            color: $fontColor;
                        }
                    }
                }
                & .input{
                    display: flex;
                    flex-direction: column;
                    gap: spacing(0.5);
                    & > .message{
                        display: flex;
                        align-items: start;
                        gap: spacing(0.5);
                        font-size: $extraSmallFontSize;
                        font-weight: 400;
                        color: $thirdFontColor;
                        margin: 0;
                        & .bold{
                            font-weight: 500;
                        }
                    }
                }
                & .sendButton{
                    margin: auto auto 0;
                }
            }
            & .button{
                &.counterOffer{
                    grid-column: 1 / 3;
                    grid-row: 1 / 2;
                    padding: spacing(1.5) spacing(3);
                    & p{
                        text-decoration: underline;
                    }
                }
                &.reject{
                    grid-column: 1 / 2;
                    grid-row: 2 / 3;
                    padding: spacing(1) 0;
                    width: 130px;
               } 
               &.accept{
                    grid-column: 2 / 3;
                    grid-row: 2 / 3;
                    padding: spacing(1) 0;
                    width: 130px;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px){
    .container {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: unset;
    }
}