@import '../../../../styles/functions.scss';

.item{
    & .icon{
        margin: 0 spacing(1) 0 0;
        width: 32px;
        height: 32px;
    }
    & .text{
        font-size: $mediumLargeFontSize;
        flex: 1;
    }
}

@media screen and (max-width: 1024px){
    .item{
        width: 200px;
        &.title{
            font-size: $mediumFontSize;
            width: 30%;
        }
        &.content{
            font-size: $extraLargeFontSize;
            width: 35%;
        }
    }
}

@media screen and (max-width: 450px){
    .item{
        width: 200px;
        &.title{
            font-size: $mediumFontSize;
            width: 30%;
        }
        &.content{
            font-size: $extraLargeFontSize;
            width: 35%;
        }
    }
}