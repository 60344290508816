@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.suggestedPricesContainer{
    display: flex;
    align-items: center;
    & > .box{
        border: 1px solid $borderColor;
        border-radius: $borderRadius;
        padding: spacing(2);
        text-align: center;
        & > p{
            font-size: $smallFontSize;
            color: $thirdFontColor;
            font-weight: 400;
            margin: 0;
            white-space: pre;
        }
    }
    & > .bigBox{
        border-color: $primaryColor;
        border-width: 2px;
        padding: spacing(3) spacing(2);
        & > p{
            font-size: $normalFontSize;
            color: $fontColor;
            font-weight: 400;
            white-space: pre;
        }
        & > p:last-child{
            font-weight: 500;
            margin-top: $defaultPadding;
        }
    }
}