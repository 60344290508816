@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.inputsContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(4);
    margin: spacing(6) 0 spacing(2);
    width: 100%;
    border-radius: 20px;
    & .input{
        max-width: 388px;
        width: 100%;
    }
}

.submitButton{
    margin: auto 0 0 !important;
}