@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.documentationContainer{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: $backgroundColor;
    & > .documentationTabs{
        & .tab{
            gap: spacing(1);
            min-height: 50px;
            flex: 1;
            &.selected{
                color: $primaryColor;
                & .icon{
                    color: $primaryColor;
                }
            }
            & .icon{
                font-size: $normalFontSize;
                color: $fontColor;
            }
        }
    }
    & > .documentationDescription{
        color: $fontColor;
        font-size: $normalFontSize;
        font-weight: 400;
        align-self: flex-start;
        margin: spacing(1) 0 spacing(2) spacing(6);
    }
    & > .documentsContainer{
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: spacing(2);
        padding: 0 spacing(6);
        box-sizing: border-box;
        & > .documentationChildren{
            display: flex;
            flex: 1;
            flex-direction: column;
        }

        & > .documentsSubTitle{
            font-weight: 400;
            font-size: $smallFontSize;
            color: $fontColor;
            align-self: flex-start;
            width: 100%;
            border-bottom: 2px solid $borderColor;
            padding-bottom: $defaultPadding;
            margin: spacing(4) 0 0 0;
        }
    }
}


@media screen and (max-width: 860px){
    .documentationContainer{
        & > .documentationTabs{
            max-width: calc(100vw - 56px - 20px);
            & > div{
                & > span{
                    display: none;
                }
                & > div{
                    &::-webkit-scrollbar {
                        display: none;
                      }
                    overflow-y: scroll !important;
                    display: block;
                }
            } 
            & .tab{
                flex: unset !important;
                width: max-content;
                &.selected{
                    color: $primaryColor;
                    border-bottom: 1px solid $primaryColor;
                    & .icon{
                        color: $primaryColor;
                    }
                }
                & .icon{
                    font-size: $normalFontSize;
                    color: $fontColor;
                }
            }
        }
        & > .documentationDescription{
            margin: spacing(1) 0 spacing(2) spacing(3);
        }
        & > .documentsContainer{
            padding: 0 spacing(3);
        }
    }
}