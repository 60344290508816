@import "../../styles//variables.scss";
@import "../../styles//functions.scss";

.toolTip{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    & > .icon{
        width: 18px;
    }
    & > .triangle{
        position: absolute;
        top: 135%;
        display: none;
        width: 40px;
        height: 40px;
        background-color: $backgroundColor;
        transform: rotate(45deg);
        box-shadow: $boxshadow;
        z-index: 998;
        &.open{
            display: flex;
        }
    }
    & > .toolTipContent{
        position: absolute;
        top: 150%;
        display: none;
        padding: spacing(2);
        max-width: 400px;
        width: max-content;
        background-color: $backgroundColor;
        border: 1px solid $borderColor;
        border-radius: $borderRadius;
        box-shadow: $boxshadow;
        z-index: 999;
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin: 0 auto;
            top: 0;
            left: 0;
            right: 0;
            box-sizing: border-box;
            border: 10px solid black;
            border-color: transparent transparent #fff #fff;
            transform-origin: 60% 25%;
            transform: rotate(135deg);
            box-shadow: -5px 5px 10px 0 rgba(0, 0, 0, 0.1);  
        }
        &.open{
            display: flex;
        }
    }
}