@import "../../../../../styles/variables.scss";
@import "../../../../../styles/functions.scss";
@import "../../../../../styles/mixins.scss";

.textNoData{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $smallFontSize;
    font-weight: 500;
    color: $thirdFontColor;
    text-align: center;
}

.cardRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: spacing(5);
    margin: 0;
        & span:first-child{
        font-weight: 500;
        color: $thirdFontColor;
        font-size: $extraSmallFontSize;
    }
    & span:last-child{
        font-weight: 400;
        color: $fontColor;
        font-size: $extraSmallFontSize;
        word-break: break-all;
        text-align: right;
        max-width: 50%;
    }
    & .chip{
        @include chips;
        display: flex;
        align-items: center;
        gap: spacing(1);
        padding: spacing(0.75) spacing(1.5);
        border-radius: $borderRadius;
        font-size: $extraExtraSmallFontSize !important;
        font-weight: 500 !important;
        text-transform: uppercase;
        cursor: pointer;
        & .photosIcon{
            object-fit: contain;
        }
    }
}

.openInform{
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: $smallFontSize;
    font-weight: 500;
    color: $primaryColor;
    margin: 0;
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
}

.inform{
    flex: 1;
    position: absolute;
    top: 120%;
    left: spacing(-3);
    right: spacing(-3);
    padding: spacing(2.5) spacing(3);
    display: flex;
    flex-direction: column;
    gap: spacing(1);
    background: $backgroundColor;
    border-radius: 0 0 $borderRadius $borderRadius;
    border-left: 1px solid $secondBackgroundColor;
    border-right: 1px solid $secondBackgroundColor;
    border-bottom: 1px solid $secondBackgroundColor;
    z-index: 100;
}