@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.inputsContainer {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: wrap;
    gap: spacing(2);
    margin: 0 0 spacing(4);
    width: 100%;

    & .inputContainer {
        position: relative;
        & .mileage {
            margin: spacing(2) auto spacing(1);

            &>p {
                font-size: 56px !important;
            }

            & input {
                font-size: 56px !important;
            }
        }

        & .error {
            color: red;
            text-align: center;
            font-size: 14px;
            position: absolute;
            bottom: -20px;
            left: 0px;
            right: 0px;
        }
    }

    & .input {
        margin: 0 auto;
        max-width: 400px;
        width: 100%;
    }
}

.submitButton {
    margin: auto 0 0 !important;
}