@import '../../../../styles/functions.scss';

.title{
    font-size: $mediumFontSize;
    width: 150px;
}
.content{
    display: flex;
    flex-wrap: wrap;
    gap: spacing(2.5) spacing(3);
    width: 100%;
}