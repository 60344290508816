@import "../../../../../styles/variables.scss";
@import "../../../../../styles/functions.scss";
@import "../../../../../styles/mixins.scss";

.textNoData{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $smallFontSize;
    font-weight: 500;
    color: $thirdFontColor;
    text-align: center;
}

.payments{
    display: flex;
    flex-direction: column;
    gap: spacing(2);
    width: 100%;
    max-height: 285px;
    overflow-y: auto;
    padding: 0 spacing(2) 0 0;
    &.valeVista{
        max-height: 290px;
        overflow-y: auto;
        padding: 0 spacing(2) 0 0;
    }
    & .blue{
        color: #157AD6 !important;
    }
    & .pie{
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > p{
            display: flex;
            flex-direction: column;
            gap: spacing(0.5);
            margin: 0;
            & .value{
                font-size: $smallFontSize;
                font-weight: 600;
                color: $fontColor;
            }
            & .date{
                font-size: $extraSmallFontSize;
                font-weight: 500;
                color: $thirdFontColor;
            }
        }
        & .state{
            font-size: $smallFontSize;
            font-weight: 400;
            &.chip{
                @include chips;
                padding: spacing(0.75) spacing(1.25);
                width: min-content;
                border-radius: $borderRadius;
                white-space: pre;
            }
            &.pending{
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
    & .subTitle{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: $extraSmallFontSize;
        font-weight: 600;
        color: $thirdFontColor;
        margin: 0;
    }
    & .arrow{
        & .icon{
            font-size: $extraSmallFontSize;
        }
    }
    & .description{
        font-size: $extraSmallFontSize;
        font-weight: 400;
        color: $fontColor;
        margin: 0;
    }
    & .cardRow{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        & span:first-child{
            font-weight: 500;
            color: $thirdFontColor;
            font-size: $extraSmallFontSize;
        }
        & span:last-child{
            font-weight: 400;
            color: $fontColor;
            font-size: $extraSmallFontSize;
            word-break: break-all;
            text-align: right;
            max-width: 50%;
        }
        & .chip{
            @include chips;
            display: flex;
            align-items: center;
            gap: spacing(1);
            padding: spacing(0.75) spacing(1.5);
            border-radius: $borderRadius;
            font-size: $extraExtraSmallFontSize !important;
            font-weight: 500 !important;
            text-transform: uppercase;
            cursor: pointer;
            & .photosIcon{
                object-fit: contain;
            }
        }
    }
    & .input{
        margin: 0 auto;
    }
    & .noFileSelected{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: spacing(1);
        margin: spacing(2) 0 0;
        padding: spacing(2) spacing(3);
        cursor: pointer;
        border: 3px dashed $borderColor;
        &:hover{
            background: rgba(0, 0, 0, 0.025);
        }
        & .fileSelector{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 10;
            cursor: pointer;
            opacity: 0;
        }
        & .noFileImg{
            width: 74px;
        }
        & p{
            font-size: $extraSmallFontSize;
            font-weight: 400;
            color: $fontColor;
            margin: 0;
        }
    }
    & .fileSelected{
        position: relative;
        margin: 0 auto;
        width: 164px;
        height: 214px;
        & .delete{
            position: absolute;
            top: spacing(1);
            right: spacing(1);
            padding: spacing(0.25);
            background: $fontColor;
            z-index: 3;
            &:hover{
                background: rgba($color: #1A1C21, $alpha: 0.9);
            }
            & .icon{
                width: 18px;
                padding: 3px;
                color: $secondFontColor;
            }
        }
        & .fileImg{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: spacing(1);
        }
    }
    & .savePayments{
        margin: auto auto 0;
        height: 48px;
    }
}

@media screen and (min-height: 784px){
    .payments{
        max-height: 372px;
        &.valeVista{
            max-height: 400px;
        }
    }
}