@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.pagination{
    display: flex;
    align-items: center;
    gap: spacing(1);
    & .button{
        display: flex;
        align-items: center;
        padding: spacing(0.5);
        &.doubleArrow{
            & .icon:first-child{
                margin: 0 spacing(-2) 0 0;
            }
        }
    }
    & .icon{
        width: 24px;
        color: $fontColor;
    }
    & .text{
        font-size: $extraSmallFontSize;
        font-weight: 500;
        color: $fontColor;
        margin: 0 spacing(3);
    }
}