@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.container{
    margin: 0 auto;
    width: 100%;
    & .timeOut{
        position: absolute;
        top: 101%;
        left: 0;
        width: 280px;
        transition: 0.25s ease-in-out all;
        opacity: 0;
        &.show{
            transition: 0.25s ease-in-out all;
            visibility: visible;
            opacity: 1;
        }
        & .loader{
          @include loaderDots;
        }
    }
}

.modalPublicationContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: spacing(2);
    & > p {
        text-align: center;
        color: $fontColor;
    }
    & > .title {
        font-size: $largeFontSize;
        margin: 1rem 0 0 0;
    }
    & > .description {
        font-size: $normalFontSize;
        margin: spacing(4) spacing(5) spacing(8);
    }
}