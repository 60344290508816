@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.content{
    flex: 1;
    background-color: $secondBackgroundColor;
    padding: spacing(6) spacing(3);
    & .questionsContainer{
        display: flex;
        flex-direction: column;
        gap: spacing(5);
        margin: 0 auto;
        max-width: 1000px;
        width: 100%;
        & .sectionContent{
            padding: spacing(6) spacing(4);
            background: $backgroundColor;
            border-radius: $borderRadius;
            box-shadow: $boxshadow;
            & .item{
                display: flex;
                flex-direction: column;
                gap: spacing(4.5);
                & .info{
                    display: flex;
                    flex-direction: column;
                    gap: spacing(3.5);
                    & .option{
                        display: flex;
                        flex-direction: column;
                        margin: 0 0 0 spacing(6);
                        gap: spacing(2.5);
                        & .optionContent{
                            display: grid;
                            grid-template-columns: auto 1fr;
                            align-items: center;
                            gap: spacing(1);
                        }
                        & .icon{
                            width: 28px;
                            color: $fontColor;
                        }
                    }
                }
                & p{
                    color: $fontColor;
                    margin: 0;
                    &.title{
                        font-size: $mediumFontSize;
                        font-weight: 500;
                    }
                    &.description{
                        font-size: $normalFontSize;
                        font-weight: 400;
                    }
                    &.data{
                        display: flex;
                        flex-direction: column;
                        font-weight: 400;
                        font-size: $smallFontSize;
                        line-height: $mediumFontSize;
                        & .subTitle{
                            font-size: $normalFontSize;
                            margin: 0 0 spacing(0.5);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .content{
        padding: spacing(3);
        & .questionsContainer{
            & .sectionContent{
                padding: spacing(4) spacing(3);
                & .item{
                    & .info{
                        & .option{
                            margin: 0 0 0 spacing(2.5);
                            & .icon{
                                width: 28px;
                            }
                        }
                    }
                    & p{
                        &.title{
                            font-size: $smallFontSize;
                        }
                        &.description{
                            font-size: $smallFontSize;
                        }
                        &.data{
                            font-size: $extraSmallFontSize;
                            & .subTitle{
                                font-size: $smallFontSize;
                            }
                        }
                    }
                }
            }
        }
    }
}