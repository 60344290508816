@import "../../../../styles/variables.scss";
@import "../../../../styles/functions.scss";

.referencesContainer{
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    align-items: center;
    & > .referenceData{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-auto-flow: row;
        gap: spacing(3) spacing(4);
        margin: spacing(2) 0 0;
        width: 80%;
        & .full{
            grid-column: span 6;
        }
        & .half{
            grid-column: span 3;
        }
        & .two{
            grid-column: span 2;
        }
    }
    & > .submitButton{
        margin: auto 0 0;
    }
}
@media screen and (max-width: 860px){
    .referencesContainer{
        & > .referenceData{
            display: flex;
            flex-direction: column;
            margin: spacing(2) 0 0;
            width: 100%;
        }
    }
}