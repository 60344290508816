@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.toast{
    display: flex;
    align-items: center;
    gap: spacing(2);
    padding: spacing(1.6) spacing(1) spacing(1.6) spacing(2);
    background: $fontColor;
    border-radius: 0.25rem;
    box-shadow: $boxshadow;
    & .message{
        display: flex;
        flex-direction: column;
        gap: spacing(0.25);
        color: $secondFontColor !important;
        margin: 0 auto 0 0;
        & .title{
            font-size: $extraExtraSmallFontSize;
            font-weight: 600;
        }
        & .message{
            font-size: $extraSmallFontSize;
            font-weight: 400;
        }
    }
    & .button{
        font-size: $extraSmallFontSize;
        font-weight: 500;
        color: $thirdFontColor;
        margin: 0;
    }
    & .close{
        width: 20px;
        color: $backgroundColor;
    }
}