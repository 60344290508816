@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';

.pickUpPoint {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: spacing(3);
    max-width: 75%;
    & .pickUpPointContainer {
        display: flex;
        flex-direction: row;
        border: 1px solid #CFCDCC;
        border-radius: $borderRadius;
        margin: spacing(2);
        cursor: pointer;


        
        & .pickUpPointText {
            & .radio {
                display: flex;
                align-items: center;
                margin: spacing(1) auto 0 auto;
            }
            display: flex;
            flex-direction: column;

            & .titlePickUpPoint {
                font-size: $normalFontSize;
                font-weight: 500;
                text-align: center;
                margin: spacing(1) 0 spacing(2) 0;

            }

            & .descriptionPickUpPoint {
                display: flex;
                flex-direction: column;
                font-size: $smallFontSize;
                font-weight: 400;
                color: $fontColor;
                text-align: center;
                margin: 0 spacing(4) spacing(4) spacing(4);

            }
        }

    }

}

.title {
    display: flex;
    flex-direction: column;
    font-size: $normalFontSize;
    font-weight: 400;
    color: $fontColor;
    text-align: center;
    max-width: 75%;
    // margin: 0;
}