@import '../../styles/variables.scss';

.input{
    position: relative;
    & .helperText{
        position: absolute;
        top: 56px;
        white-space: nowrap;
    }
}
li.flag{
    width: 77px;
    display: flex;
    justify-content: center;
    font-size: $largeFontSize;
    padding: 0;
}