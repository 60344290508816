@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";
@import "../../../styles/mixins.scss";

.favoritesContainer{
    display: flex;
    flex-direction: column;
    gap: spacing(2);
    & .search{
        display: flex;
        justify-content: end;
        & .inputSearch{
            max-width: 440px;
        }
    }
    & .pagination{
        margin: 0 auto;
    }
    & .list{
        width: 100%;
        height: 100%;
        overflow-y: auto;
        & .cardContainer{
            display: flex;
            flex-direction: row;
            & > img{
                width: 104px;
                height: 104px;
                margin: spacing(2);
                border-radius: 12px;
            }
            & .paused{
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
            }
            & > .cardDescription{
                display: flex;
                flex-direction: column;
                position: relative;
                justify-content: center;
                flex: 1;
                & > .cardFavorite{
                    position: absolute;
                    top: 24px;
                    right: 0px;
                }
                & .text{
                    text-align: start;
                    margin: spacing(.5) 0;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                & .price{
                    white-space: pre;
                }
                & .state{
                    @include chips;
                    font-size: $extraExtraSmallFontSize !important;
                    font-weight: 500;
                    padding: spacing(0.75) spacing(1.25);
                    width: min-content;
                    border-radius: $borderRadius;
                    white-space: pre;
                }
                & > .cardOptionsContainer{
                    display: flex;
                    flex-direction: row;
                    align-items: center
                }
            }
            & > .cardAction{
                
            }
        }
        & .table{
            @include table;
            & .row{
                cursor: pointer;
                & .picture{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 60px;
                    & img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: $borderRadius;
                    }
                }
            }
        }
    }
}

.loaderFavorites{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: spacing(2);
    & .loader{
        width: 28px !important;
        height: 28px !important;
        color: $fontColor;
    }
    & .text{
        font-size: $smallFontSize;
        font-weight: 500;
        color: $fontColor;
        margin: 0;
    }
}

.noFavorites{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: spacing(4) 0;
    height: 100%;
    & img{
        width: 286px;
    }
    & p{
        display: flex;
        flex-direction: column;
        color: $fontColor;
        gap: spacing(1) 0;
        text-align: center;
        & .title{
            font-size: $normalFontSize;
            font-weight: 400;
        }
        & .description{
            font-size: $extraSmallFontSize;
            font-weight: 300;
            max-width: 340px;
        }
    }
}
@media screen and (max-width: 1024px){
    .favoritesContainer{
        & .search{
            & .inputSearch{
                max-width: unset;
                align-items: center;
            }
        }
    }
}