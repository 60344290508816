@import "../../../../../styles/variables.scss";
@import "../../../../../styles/functions.scss";

.rightButtons{
    display: flex;
    align-items: center;
    margin-right: 4px;
    & > .button{
        margin: 0;
        font-size: 15px;
        color: #676767;
        font-weight: 500;
        margin: 0 12px;
        cursor: pointer;
        &:hover{
            text-shadow: 0 0 0.5px $mainFontColor, 0 0 0.5px $mainFontColor;
        }
    }
    & > .separator{
        height: 100%;
        width: 1px;
        background-color: #676767;
    }
}