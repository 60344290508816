@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';

.scheduleInspection{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: spacing(4);
    margin: 0 auto spacing(3);
    max-width: 75%;
    width: 100%;
    & .title{
        display: flex;
        flex-direction: column;
        font-size: $normalFontSize;
        font-weight: 400;
        color: $fontColor;
        text-align: center;
        margin: 0;
    }
    & .item{
        display: flex;
        flex-direction: column;
        gap: spacing(2);
        width: 100%;
        & > .name{
            font-size: $mediumFontSize;
            font-weight: 500;
            color: $fontColor;
            margin: 0;
        }
        &.dates {
            min-height: 200px;
            & .content{
                position: relative;
                align-items: center;
                gap: spacing(1.5);
                width: 100%;
                & .date{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 95px;
                    height: 96px;
                    border: 1px solid $thirdFontColor;
                    border-radius: 1.5rem;
                    & p{
                        display: flex;
                        flex-direction: column;
                        margin: 0;
                        font-size: $smallFontSize;
                        font-weight: 400;
                        color: $fontColor;
                        text-align: center;
                        text-transform: none;
                    }
                }
            }
            & .description{
                font-size: $smallFontSize;
                font-weight: 400;
                color: $fontColor;
                margin: 0 auto;
                max-width: 344px;
                text-align: center;
                & span{
                    font-weight: 600;
                }
            }
        }
        &.participants{
            & .content{
                display: flex;
                justify-content: center;
                padding: 0 spacing(2.5);
                gap: spacing(4.5);
                flex: 1;
                & .people{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: spacing(2);
                    &.you{
                        & img{
                            width: 70px;
                            height: 70px;
                            object-fit: cover;
                            border-radius: 100px;
                        }
                    }
                    &.linzer{
                        & .image{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: spacing(1.5);
                            width: 46px;
                            height: 46px;
                            background: #7100FF;
                            border-radius: 100px;
                            & img{
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                    & p{
                        font-size: $smallFontSize;
                        font-weight: 400;
                        color: $fontColor;
                        margin: 0;
                    }
                }
            }
        }
    }
}

.modalBuy{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(5);
    padding: spacing(5) spacing(3) spacing(2);
    & .message{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $fontColor;
        text-align: center;
        margin: 0;
        & .title{
            font-size: $largeFontSize;
            font-weight: 400;
            margin: 0 0 spacing(2) 0;
        }
        & .subtitle{
            font-size: $mediumFontSize;
            font-weight: 400;
            margin: spacing(3) 0 spacing(3);
            max-width: 316px;
        }
        & .description{
            font-size: $normalFontSize;
            font-weight: 400;
            line-height: $mediumLargeFontSize;
        }
    }
}

.modalValidation{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(5);
    padding: spacing(5) spacing(4) spacing(2);
    & .message{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $fontColor;
        text-align: center;
        margin: 0;
        & .title{
            font-size: $largeFontSize;
            font-weight: 400;
            margin: 0 0 spacing(3);
        }
        & .description{
            font-size: $extraSmallFontSize;
            font-weight: 400;
            max-width: 374px;
            line-height: 22px;
        }
    }
}

.submitButton{
    margin: auto 0 0 !important;
}