@import '../../../../styles/functions.scss';

.item{
    &.description{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        font-size: $mediumFontSize;
        width: 30%;
    }
    &.title{
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        font-size: $extraLargeFontSize;
        width: 35%;
    }
    &.version{
        grid-column: 1 / 4;
        font-size: $mediumLargeFontSize;
        width: 30%;
    }
    &.price{
        grid-column: 1 / 4;
        font-size: $extraLargeFontSize;
        width: 50%;
    }
    &.share{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        width: 32px;
        height: 32px;
    }
    &.favorite{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        width: 32px;
        height: 32px;
    }
}

.button{
    grid-column: 1 / 4;
    font-size: 56px;
    width: 100%;
}

@media screen and (max-width: 1024px){
    .item{
        &.description{
            font-size: $normalFontSize;
        }
        &.title{
            font-size: $mediumLargeFontSize;
        }
        &.version{
            font-size: $mediumFontSize;
        }
        &.price{
            font-size: $mediumFontSize;
        }
    }
}