@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.cardDetailContainer{
    display: grid;
    grid-template-columns: 1fr auto;
    grid-auto-flow: row;
    gap: spacing(2) spacing(8);
    flex: 1;
    padding: spacing(3) spacing(6);
    justify-content: center;
    & .carImage{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        border-radius: $borderRadius;
        width: 368px;
        height: 536px;
        object-fit: cover;
        &.skeleton{
            width: 368px;
            height: 536px;
        }
    }
    & > .cardContainer{
        display: flex;
        flex: 1;
        flex-direction: column;
        & > .noChildren{
            display: flex;
            flex-direction: column;
            margin: 0 spacing(2);
            & > .cardTitleContainer{
                display: flex;
                align-items: center;
                & > .cardTitle{
                    font-size: $mediumFontSize;
                    color: $fontColor;
                    font-weight: 500;
                    margin-right: $defaultPadding;
                    overflow: hidden;
                    white-space: nowrap;
                }
                & .showDetail{
                    & img{
                        width: 18px;
                    }
                    & .text{
                        font-size: $smallFontSize;
                        font-weight: 400;
                        color: $fontColor;
                        margin: 0;
                    }
                }
            }
            & > .priceTitle{
                font-size: $normalFontSize;
                color: $fontColor;
                margin: 0 auto $defaultPadding 0;
                &.skeleton{
                    width: 40%;
                }
            }
            & > .price{
                font-size: $largeFontSize;
                color: $fontColor;
                font-weight: 700;
                margin: 0 auto 0 0;
                &.skeleton{
                    width: 50%;
                    height: 48px;
                }
            }
        }
        & > .separator{
            width: 100%;
            height: 1px;
            background-color: $thirdFontColor;
            margin: $defaultPadding 0;
        }
        & > .children{
            display: flex;
            flex: 1;
            flex-direction: column;
            margin: 0 spacing(2);
        }
    }
    & > .children.right{
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        white-space: normal;
        display: flex;
    }
    & > .bottomDetail{
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        display: flex;
        flex-direction: column;
        margin-right: spacing(2);
        & > .carImage{
            border-radius: $borderRadius;
            width: 368px;
        }
        & > .cardTitleContainer{
            display: flex;
            align-items: center;
            & > .cardTitle{
                font-size: $mediumFontSize;
                color: $fontColor;
                font-weight: 500;
                margin-right: $defaultPadding;
            }
        }
        & > .priceTitle {
            font-size: $normalFontSize;
            color: $fontColor;
            margin: 0 auto $defaultPadding 0;
        }
        & > .price{
            font-size: $largeFontSize;
            color: $fontColor;
            font-weight: 700;
            margin: 0 auto 0 0;
        }
    }
    & > .onlyPicture{
        height: 100%;
        &.children{
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
        }
    }
}