@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.modalContainerOffers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: spacing(2);

    &>img {
        filter: invert(100%);
    }

    &>p {
        color: $fontColor;
        margin: 0;
        text-align: center;
    }

    &>.title {
        font-size: $largeFontSize;
        font-weight: 400;
    }

    &>.subTitle {
        font-size: $normalFontSize;
        margin: 0 spacing(6);
    }

    &>.value {
        font-size: $largeFontSize;
        font-weight: 700;
    }

    &>.description {
        font-size: $smallFontSize;
        margin: 0 spacing(9);
    }

    &>.button {
        margin: spacing(2) 0 0;
    }
}