@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.modalContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(5);
    padding: spacing(5) spacing(4) spacing(2);
    & .message{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $fontColor;
        text-align: center;
        margin: 0;
        & .title{
            font-size: $mediumFontSize;
            font-weight: 400;
            margin: 0 0 spacing(3);
        }
        & .description{
            font-size: $smallFontSize;
            font-weight: 400;
            max-width: 374px;
            line-height: 22px;
        }
    }
    & .buttons{
        display: flex;
        align-items: center;
        gap: spacing(4);
    }
}