@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.modalContainer{
    display: flex;
	flex-direction: column;
	align-items: center;
	gap: spacing(3);
	padding: spacing(8) spacing(3) spacing(6) !important;
	max-width: 500px;
	width: 100% !important;
	min-width: auto !important;
	top: 0 !important;
	left: 0 !important;
	right: 0;
	bottom: 0;
	margin: auto;
	transform: none !important;
	height: min-content;
	& > .closeButton{
		position: absolute;
		top: spacing(2);
		right: spacing(2);
		background: transparent;
		outline: none;
		border: none;
	}
	& > p{
		color: $secondFontColor;
		text-align: center;
		margin: 0 !important;
		&.title{
			font-size: $mediumFontSize;
		}
		&.description{
			font-size: $extraSmallFontSize;
			display: flex;
			flex-direction: column;
			width: 70%;
		}
	}
	& .buttons{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
		gap: spacing(2);
		& .link{
			height: 3.5rem;
			cursor: pointer;
			& img{
				height: 100%;
			}
		}
	}
}

@media screen and (max-width: 768px){
	.redirectModalContainer{
		padding: spacing(8) spacing(1) spacing(6) !important;
	}
}

@media screen and (max-width: 500px){
	.redirectModalContainer{
		top: 0 !important;
		left: 0 !important;
		transform: none !important;
		margin: 0;
		max-width: initial;
		width: 100vw !important;
	}
}