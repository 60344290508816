@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';

.summary{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(1);
    margin: 0 auto spacing(3);
    max-width: 66%;
    width: 100%;
    & .line{
        width: 100%;
        border: 0.5px solid $borderColor;
    }
    & .title{
        font-size: $normalFontSize;
        font-weight: 400;
        color: $fontColor;
        text-align: center;
        margin: 0;
    }
    & .priceCar{
        font-size: 50px;
        font-weight: 400;
        color: $fontColor;
        text-align: center;
        margin: 0 0 spacing(1);
    }
    & .item{
        display: flex;
        flex-direction: column;
        gap: spacing(1);
        width: 100%;
        & .label{
            font-size: $smallFontSize;
            font-weight: 500;
            margin: spacing(0.5) auto spacing(1.5);
        }
        & p{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 400;
            color: $fontColor;
            margin: 0;
            & .name{
                font-size: $smallFontSize;
            }
            & .value{
                font-size: $normalFontSize;
            }
        }
    }
    & .message{
        font-size: 12px;
        font-weight: 400;
        color: $fontColor;
        text-align: center;
        margin: 0;
        & .link{
            margin: spacing(0.5);
            font-weight: 500;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.submitButton{
    margin: auto 0 0 !important;
}
