@import "../../../../../styles/variables.scss";
@import "../../../../../styles/functions.scss";

.offerContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > .offerRow{
        display: flex;
        flex-direction: column;
        gap: spacing(0.5);
        & > p{
            margin: 0;
        }
        & > p:first-child{
            font-weight: 600;
            color: $thirdFontColor;
            font-size: $smallFontSize;
        }
        & > p:last-child{
            font-weight: 400;
            color: $fontColor;
            font-size: $extraSmallFontSize;
        }
    }
}