@import '../../../../styles/functions.scss';

.item{
    &.title{
        font-size: $extraLargeFontSize;
        width: 30%;
    }
    &.content{
        font-size: $mediumLargeFontSize;
        width: 100%;
    }
}

@media screen and (max-width: 1024px){
    .item{
        &.content{
            font-size: $smallFontSize;
        }
    }
}