@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/functions.scss';

.skeletonContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    & > .skeletonRow{
        display: flex;
        flex: 1;
        flex-direction: row;
        border-bottom: 1px solid $borderColor;
        padding-bottom: spacing(1.5);
        justify-content: space-between;
        margin-top: spacing(1.5);
        align-items: center;
        width: 100%;
    }
}