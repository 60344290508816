@import "../../../../styles/variables.scss";
@import "../../../../styles/functions.scss";

.salaryContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: spacing(3);
    width: 100%;

    &>.salaryDataContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: spacing(5);
        margin: 0 0 spacing(4);

        &>.item {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: spacing(1);

            &>.title {
                font-size: $normalFontSize;
                font-weight: 400;
                color: $fontColor;
                margin: 0;
            }

            &>.itemContent {
                margin: 0 auto;
            }

            &>.optionsAntiquity {
                display: flex;
                flex-wrap: wrap;
                gap: spacing(2);
            }

            & .liquidRentInput {
                margin: 0 auto;
            }

            &>span {
                font-size: $extraExtraSmallFontSize;
                font-weight: 400;
                text-align: center;
            }
        }
    }

    & .buttons {
        display: flex;
        flex: 1;
        justify-content: center;
    }
}

@media screen and (max-width: 860px) {
    .salaryContainer {
        &>.salaryDataContainer {
            &>.item {
                &>.optionsAntiquity {
                    gap: spacing(2) spacing(6);
                    justify-content: center;
                }
            }
        }
    }
}