@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.searchContainer{
    position: relative;
    display: flex;
    flex: 1;
    justify-content: end;
    padding: spacing(5) spacing(12) spacing(4) 0;
    background: url("../../assets/images/background_sell.webp") no-repeat center center;
    background-size: auto auto;
    background-position: top;
    & > .sellModalContainer{
        position: relative;
        display: flex;
        flex-direction: column;
        top: 0;
        bottom: 0;
        padding: spacing(3) spacing(5) spacing(5);
        max-width: 566px;
        width: 100%;
        background-color: $backgroundColor;
        border-radius: $borderRadius;
        & > .headerModal{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: spacing(1);
            width: 100%;
            align-items: center;
            & > .title{
                font-size: $mediumFontSize;
                font-weight: 600;
                color: $fontColor;
                text-align: center;
                margin: 0;
            }
            & > .stepperNumber{
                color: $thirdFontColor;
                font-size: $normalFontSize;
                margin: 0;
            }
            & > .disabled{
                visibility: hidden;
            }
            & .backButton{
                margin: 0 0 0 spacing(-1);
                & .icon{
                    width: 32px;
                    color: $fontColor;
                }
            }
        }
        & > .searchBody {
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: center;
            & > .searchModalContainer {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                & > .title{
                    font-size: $mediumFontSize;
                    font-weight: 600;
                    color: $fontColor;
                    text-align: center;
                    margin: spacing(4) 0 spacing(3);
                }
                & > .subTitleBody{
                    font-size: $normalFontSize;
                    font-weight: 400;
                    color: $fontColor;
                    margin: 0 0 spacing(6);
                    max-width: 490px;
                    text-align: center;
                }
            }
        }
    }
}
.topLine{
    height: 12px;
    background-color: $fourBackgroundColor;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    .currentStep{
        background-color: $primaryColor;
    }
}

@media screen and (max-width: 768px) {
    .searchContainer{
        justify-content: unset;
        padding: 0;
        & > .sellModalContainer{
            max-width: unset;
            border-radius: unset;
            padding: spacing(3) 0 spacing(5);
        }
    }
}