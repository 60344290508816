@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.inputsContainer {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: wrap;
    gap: spacing(4);
    margin: 0 0 spacing(4);
    width: 100%;
    & .input{
        margin: 0 auto;
        max-width: 390px;
        width: 100%;
    }
    & > .item{
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: spacing(2);
        width: 100%;
        & > .title{
            font-weight: 600;
            font-size: $normalFontSize;
            color: $thirdFontColor;
            margin: 0;
        }
        & > .toogleButtonSquareGroup {
            justify-content: center;
            flex-wrap: wrap;
            gap: spacing(3);
            margin: 0 auto;
            max-width: 420px;
            width: 100%;
            & .toogleButtonSquare {
                margin: 0;
                padding: spacing(0.5) spacing(2);
                font-size: $extraExtraSmallFontSize;
                border: 1px solid rgba(0, 0, 0, 0.12) !important;
                border-radius: $borderRadius !important;
            }
        }
    }
}

.submitButton{
    margin: auto 0 0 !important;
}