@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.profileCardContainer{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: spacing(0.75) spacing(1.25);
    gap: spacing(1);
    & .children{
        flex: 1;
        position: relative;
        flex-direction: column;
        padding: spacing(3) spacing(2.5) spacing(1);
        min-width: 800px;
        background: $backgroundColor;
        border-radius: $borderRadius;
    }
    & > .breadCumbsContainer{
        display: flex;
        justify-content: space-between;
    }
}
@media screen and (max-width: 1024px){
    .profileCardContainer{
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: spacing(0.75) spacing(1.25);
        gap: spacing(1);
        & .children{
            flex: 1;
            position: relative;
            flex-direction: column;
            padding: 0;
            min-width: unset;
        }
    }
}