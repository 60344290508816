@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/functions.scss';

.skeletonContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & > .skeletonLeft{
        display: flex;
        flex-direction: column;
    }
}