@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';

.content{
    flex: 1;
    background-color: $secondBackgroundColor;
    padding: spacing(6) spacing(3);
    & .questionsContainer{
        display: flex;
        flex-direction: column;
        gap: spacing(5);
        margin: 0 auto;
        max-width: 1000px;
        width: 100%;
        & .sectionContent{
            padding: spacing(6) spacing(4);
            background: $backgroundColor;
            border-radius: $borderRadius;
            box-shadow: $boxshadow;
            position: relative;
            & > .goBack{
                display: flex;
                top: spacing(2);
                left: spacing(2.3);
                position: absolute;
                & > .icon{
                    color: black;
                    width: 32px;
                    height: 32px;
                }
            }
            & > p{
                margin: 0;
                font-weight: 500;
            }
            & > .item{
                display: flex;
                flex-direction: column;
                & > p:last-child{
                        margin-top: 0;
                        font-weight: 500;
                }
                & .title{
                    font-size: $mediumFontSize;
                    font-weight: 500;
                }
            }
            & > .numberItem{
                margin-top: 0;
                & > .picturesExample{
                    display: flex;
                    justify-content: center;
                    gap: spacing(8);
                    margin: spacing(4) 0;

                }
            }
            & > .section{
                & > .bullet{
                    font-weight: 500;
                    margin-bottom: spacing(1);
                }   
            }
       
        }
    }
}

@media screen and (max-width: 860px) {
    .content{
        & .questionsContainer{
            & .sectionContent{
                & > .numberItem{
                    & > .picturesExample{
                        display: flex;
                        justify-content: center;
                        & > img{
                            width: 36vw;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 655px) {
    .content{
        & .questionsContainer{
            & .sectionContent{
                & > .numberItem{
                    & > .picturesExample{
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        gap: spacing(2);
                        & > img{
                            width: 260px;
                            margin-left: -40px;
                        }
                    }
                }
            }
        }
    }
}