@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';

.additionalCost{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: spacing(2);
    margin: 0 auto;
    max-width: 66%;
    width: 100%;
    & .skeleton{
        width: 24%;
        height: 23.5px;
    }
    & .title{
        font-size: $normalFontSize;
        font-weight: 400;
        color: $fontColor;
        text-align: center;
        margin: 0;
        max-width: 342px;
        &.costs{
            margin: 0 0 spacing(2);
        }
        &.pay{
            margin: spacing(2) 0 0;
        }
    }
    & .line{
        width: 100%;
        border: 0.5px solid $borderColor;
    }
    & .info{
        display: flex;
        flex-direction: column;
        gap: spacing(1);
        width: 100%;
        & p{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 400;
            color: $fontColor;
            margin: 0;
            & .name{
                font-size: $smallFontSize;
            }
            & .value{
                font-size: $normalFontSize;
            }
            &.bold{
                font-weight: 500;
            }
            &.total{
                & .skeleton{
                    height: 28.5px;
                }
                & .value{
                    font-size: $mediumFontSize;
                }
            }
        }
    }
}

.submitButton{
    display: flex;
    flex: 1;
    margin: spacing(8) 0 0;
    & .button{
        margin: auto 0 0;
    }
}