@import "../../../../../styles/variables.scss";
@import "../../../../../styles/functions.scss";

.timeLine{
    padding: 0;
    margin: 0 auto auto;
    & .content{
        flex: 1;
        display: flex;
        flex-direction: column;
        & .opposite{
            display: none;
        }
        & .dot,
        & .connector{
            background: $primaryColor;
            color: $primaryColor;
        }
        & .lastDot {
            background: white;
            color: $primaryColor;
            border-color: $primaryColor;
        }
        & .outlinedPrimary{
            background: none;
        }
        & .image{
            flex: 1;
            position: absolute;
            align-self: center;
            bottom: 8px;
            width: 70%;
            object-fit: contain;
            user-select: none;
        }
        & .text{ 
            display: flex;
            flex-direction: column;
            gap: 0;
            font-size: $extraSmallFontSize;
            font-weight: 400;
            color: $thirdFontColor;
            margin: 0;
            &.lastItem{
                & .title{
                    color: $primaryColor;
                }
                & .price{
                    font-size: $smallFontSize;
                    font-weight: 500;
                    color: $fontColor;
                }
            }
            & .title{
                font-size: $smallFontSize;
                font-weight: 500;
            }
        }
    }
}