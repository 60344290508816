@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.checkbox{
    display: flex;
    align-items: center;
    margin: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    gap: spacing(1);
    cursor: pointer;
    user-select: none;
    & .checker{
        padding: 0;
        color: $fontColor !important;
        & .Mui-checked{
            color: $fontColor !important;
        }
    }
}