@import "../../../../styles/variables.scss";
@import "../../../../styles/functions.scss";
@import "../../../../styles/mixins.scss";

.myBuysContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: spacing(2);
    & .noBuys{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    & .pagination{
        margin: 0 auto;
    }
    & .myBuys{
        width: 100%;
        height: 100%;
        overflow-y: auto;
        & .cardContainer{
            display: flex;
            flex-direction: row;
            & > img{
                width: 104px;
                height: 104px;
                margin: spacing(2);
                border-radius: 12px;
            }
            & .paused{
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
            }
            & > .cardDescription{
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1;
                & .text{
                    text-align: start;
                    margin: spacing(.5) 0;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                & .price{
                    white-space: pre;
                }
                & .state{
                    @include chips;
                    font-size: $extraExtraSmallFontSize !important;
                    font-weight: 500;
                    padding: spacing(0.75) spacing(1.25);
                    width: min-content;
                    border-radius: $borderRadius;
                    white-space: pre;
                }
                & > .cardOptionsContainer{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }
            }
            & > .cardAction{
                
            }
        }
        & .table{
            @include table;
            & .row{
                cursor: pointer;
                & .picture{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 60px;
                    & img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: $borderRadius;
                    }
                }
                & .text{
                    text-align: start;
                }
                & .price{
                    white-space: pre;
                }
                & .state{
                    @include chips;
                    font-size: $extraExtraSmallFontSize !important;
                    font-weight: 500;
                    padding: spacing(0.75) spacing(1.25);
                    width: min-content;
                    border-radius: $borderRadius;
                    white-space: pre;
                }
                & .action{
                    position: relative
                }
            }
    
        }
    }
}

.loaderBuys{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: spacing(2);
    & .loader{
        width: 28px !important;
        height: 28px !important;
        color: $fontColor;
    }
    & .text{
        font-size: $smallFontSize;
        font-weight: 500;
        color: $fontColor;
        margin: 0;
    }
}

.myPublicationCardCypress{
    position: relative !important;
}