@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.selected{
    background-color: rgba(159, 133, 236, 0.7) !important;
    color: $secondFontColor !important;
    & p{
        color: $secondFontColor !important;
    }
}

.toggleButtonSingle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px !important;
    height: 96px !important;
    border: 1px solid $thirdFontColor !important;
    border-radius: $borderRadius !important;
    &.skeleton{
        border: none !important;
    }
    & p{
        display: flex;
        flex-direction: column;
        margin: 0;
        font-size: $smallFontSize;
        font-weight: 400;
        line-height: $normalFontSize;
        color: $fontColor;
        text-align: center;
        text-transform: none;
    }
}

.toggleButtonGroup{
    &.small{
        &.skeleton{
            width: 180px;
            height: 35px !important;
        }
        & > *{
            padding: spacing(0.75) spacing(4) !important;
        }
        & .input{
            min-width: 90px;
        }
    }
    &.skeleton{
        width: 260px;
        height: 50px;
        border-radius: $borderRadius !important;
    }
    & > :not(.selected){
        color: $thirdFontColor;
        border: 1px solid $thirdFontColor;
    }
    & :first-child{
        border-radius: $borderRadius 0 0 $borderRadius !important;
    }
    & :last-child{
        border-radius: 0 $borderRadius $borderRadius 0 !important;
    }
    & > *{
        font-size: $extraExtraSmallFontSize;
        font-weight: 500;
        padding: spacing(1.5) spacing(4) !important;
    }
    & .input{
        min-width: 150px;
    }
}

@media screen and (max-width: 860px){
    .toggleButtonSingle{
        width: 65px !important;
        height: 66px !important;
    }
    
    .toggleButtonGroup{
        &.small{
            &.skeleton{
                width: 180px;
                height: 35px !important;
            }
            & > *{
                padding: spacing(0.75) spacing(2) !important;
            }
            & .input{
                min-width: 90px;
            }
        }
        & > *{
            padding: spacing(1.5) spacing(2) !important;
        }
        &.skeleton{
            width: 260px;
            height: 50px;
        }
        & .input{
            font-size: 12px;
            min-width: 120px;
        }
    }
}