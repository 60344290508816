@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';

.financing{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: spacing(4);
    margin: 0 0 spacing(3);
    & .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: spacing(2);
        & .title{
            font-size: $normalFontSize;
            font-weight: 400;
            color: $fontColor;
            margin: 0 auto;
        }
        & .subtitle{
            font-size: $extraSmallFontSize;
            font-weight: 400;
            color: $fontColor;
        }
        &.priceCar{
            & .subtitle{
                text-align: center;
            }
            & .content{
                & p{
                    font-size: 50px;
                    font-weight: 400;
                    color: $fontColor;
                    margin: 0;
                }
            }
        }
        &.fees{
            & .subtitle{
                margin: 0;
                max-width: 350px;
                text-align: center;
            }
            & .input{
                & > p{
                    font-size: 50px !important;
                }
                & input{
                    font-size: 50px !important;
                }
            }
            & .content{
                display: flex;
                gap: spacing(5);
                & .option{
                    padding: 0 !important;
                    width: 74px !important;
                    height: 60px !important;
                    border-radius: $borderRadius !important;
                }
                & .disabled{
                    opacity: .3; 
                    cursor: not-allowed;
                    // background-color: $secondBorderColor !important;
                }
                .termOption {
                    position: relative;
                    display: inline-block;
                    
                    &:hover .tooltip {
                        display: block;
                    }
                    .tooltip {
                        display: none;
                        position: absolute;
                        top: -40px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: #333;
                        color: white;
                        padding: 5px 10px;
                        border-radius: 4px;
                        font-size: 12px;
                        white-space: nowrap;
                        z-index: 1000;

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: -5px;
                            left: 50%;
                            transform: translateX(-50%);
                            border-width: 5px;
                            border-style: solid;
                            border-color: #333 transparent transparent transparent;
                        }
                    }
                }
            }
        }
    }
}

.submitButton{
    margin: auto 0 0;
}