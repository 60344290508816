@import '../../styles/variables.scss';

.favorite{
    display: flex;
    align-items: flex-end;
    gap: spacing(1);
    cursor: pointer;
    &.black{
        & .icon,
        & .text{
            color: $fontColor;
        }
    }
    &.blue{
        & .icon,
        & .text{
            color: $blueColor;
        }
    }
    &.small{
        & .icon{
            width: 24px;
        }
    }
    & .icon{
        width: 24px;
    }
    & .text{
        font-size: $normalFontSize;
        font-weight: 400;
        margin: 0;
    }
}

.skeleton{
    width: 32px !important;
    height: 32px !important;
}