@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.card{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 343px;
    height: 350px;
    background: $backgroundColor;
    border: 1px solid $borderColor;
    border-radius: 0.5rem;
    cursor: pointer;
    &:hover{
        background: rgb(235, 235, 235);
    }
    &:active{
        background: rgb(255, 255, 255);
    }
    & > .sold{
        position: absolute;
        top: spacing(2);
        left: 0;
        margin: 0;
        padding: spacing(0.75) spacing(3.5);
        background: #F70000;
        font-size: $extraSmallFontSize;
        font-weight: 600;
        color: $secondFontColor;
        border-radius: 0 $borderRadius $borderRadius 0;
        letter-spacing: spacing(0.35);
        text-transform: uppercase;
    }
    & > .imageCard{
        width: 100% !important;
        height: 60% !important;
        border-radius: 0.5rem 0.5rem 0 0;
        object-fit: cover;
    }
    & .certified{
        position: absolute;
        top: 50%;
        left: spacing(2.8);
    }
    & > .information{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 92px;
        padding: spacing(2.5) spacing(2.8) spacing(3.5);
        & p{
            margin: 0;
            color: $fontColor;
        }
        & > .info{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            & .text{
                display: flex;
                flex-direction: column;
                font-weight: 400;
                width: 70%;
                & .skeleton{
                    width: 100%;
                }
                & .name{
                    font-size: $mediumFontSize;
                    text-transform: capitalize;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                & .data{
                    font-size: $extraSmallFontSize;
                }
            }
        }
        & .price{
            display: flex;
            align-items: center;
            gap: 0 spacing(1.4);
            white-space: nowrap;
            & .skeleton{
                font-size: $smallFontSize !important;
                width: 100%;
            }
            & .principal{
                font-size: $mediumLargeFontSize;
                font-weight: 700;
            }
            & .old{
                font-size: $smallFontSize;
                font-weight: 400;
                text-decoration: line-through;
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .card{
        width: 100%;
    }
}