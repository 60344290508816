@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';

.creditRequest{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: spacing(5);
    margin: 0 0 spacing(3);
    & .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        &.liquidRent{
            & .input{
                & > p{
                    font-size: 50px !important;
                }
                & input{
                    font-size: 50px !important;
                }
            }
        }
        & .title{
            font-size: $normalFontSize;
            font-weight: 400;
            color: $fontColor;
            margin: 0 auto;
        }
        & .subtitle{
            font-size: $extraExtraSmallFontSize;
            font-weight: 400;
            color: $fontColor;
            margin: 0;
        }
        &.liquidRent{
            & .subtitle{
                text-align: center;
            }
        }
        &.seniority{
            & .subtitle{
                margin: 0 auto;
            }
            & .content{
                display: flex;
                gap: spacing(5);
                padding: 0 0 20px 0;
                & .option{
                    padding: 0 !important;
                    width: 74px !important;
                    height: 60px !important;
                    border-radius: $borderRadius !important;
                }
            }
        }
        & .content{
            margin: spacing(2) 0 0;
        }
    }
}

.submitButton{
    margin: auto 0 0;
}