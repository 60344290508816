@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.tagContainer {
    display: flex;
    align-items: center;
    gap: spacing(1);
    padding: spacing(0.5) spacing(0.8);
    border: 1px solid $thirdFontColor;
    border-radius: $borderRadius;
    &.editable{
        padding: spacing(0.5) spacing(0.8) spacing(0.5) spacing(1.8);
    }
    & .close{
        padding: spacing(0.25);
        & .icon{
            width: 16px;
            color: $fontColor;
        }
    }
    & .text{
        font-size: $extraSmallFontSize;
        font-weight: 400;
        color: $fontColor;
        margin: 0;
    }
}

.skeleton{
    font-size: $mediumFontSize;
    width: 80px;
}

@media screen and (max-width: 1024px){
    .tagContainer {
        gap: spacing(1);
        padding: spacing(0.5) spacing(1);
        border: 1px solid $thirdFontColor;
        border-radius: $borderRadius;
        &.editable{
            padding: spacing(0.5) spacing(1) spacing(0.5) spacing(2);
        }
        & .text{
            font-size: $smallFontSize;
        }
    }

    .skeleton{
        font-size: $mediumFontSize;
        width: 80px;
    }
}