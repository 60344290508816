@import '../../styles/variables.scss';
@import '../../styles/functions.scss';

.downloadApp{
    display: flex;
	align-items: center;
	gap: spacing(1);
	padding: spacing(2);
	/* position: absolute; */
	top: 0 !important;
	left: 0 ;
	right: 0;
	background: $backgroundColor;
	border-top: 1px solid $borderColor;
	border-bottom: 1px solid $borderColor;
	& .close{
		padding: 0 !important;
		& .icon{
			width: 20px;
			color: #1A1C21;
		}
	}
	& > img{
		width: 64px;
	}
	& > p{
		display: flex;
		flex-direction: column;
		align-items: start;
		color: #1A1C21;
		margin: 0;
		& .title{
			font-size: $extraSmallFontSize;
			font-weight: 500;
		}
		& .text{
			font-size: $extraExtraSmallFontSize;
			font-weight: 400;
		}
	}
	& > .button{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: spacing(1.5) spacing(3);
		background: #FCFCFC;
		border-radius: $borderRadius;
		margin: 0 0 0 auto;
		border: 2px solid #7100FF;
		& p{
			font-size: $smallFontSize;
			margin: 0;
			color: #1A1C21;
		}
	}
}
.home{

	z-index: 999999;
}