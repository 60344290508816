@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.sidebarContainer{
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: spacing(11) 0 0 spacing(6);
    background: $backgroundColor;
    box-shadow: $boxshadow;
    z-index: 6;
    transition: 1s ease-in-out;
    overflow-y: auto;
    &.open{
        right: 0;
    }
    &.variantClose{
        padding: spacing(10) 0 0;
        & .back {
            &:hover .icon{
                transform: rotate(90deg);
            }
        }
    }
    & .title{
        display: flex;
        flex-direction: column;
        gap: spacing(0.5);
        margin: 0 0 spacing(2);
        padding: spacing(1) spacing(3);
        & p{
            font-size: $mediumFontSize;
            font-weight: 400;
            color: $fontColor;
            margin: 0;
        }
        & .line{
            width: 100%;
            height: 1px;
            background: $fourBackgroundColor;
        }
    }
    & .back {
        position: absolute;
        left: auto;
        right: 16px;
        &:hover .icon{
            transform: rotate(180deg);
            transition: 0.3s ease-in-out;
        }
        & .icon{
            font-size: $largeFontSize;
            transition: 0.3s ease-in-out;
            color: $thirdBackgroundColor;
        }
    }
}

.bkgrResponsive{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    background: rgba($color: #1A1C21, $alpha: 0.5);
    transition: 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    &.open{
        visibility: visible;
        opacity: 1;
        transition: 0.3s ease-in-out;
    }
}

@media screen and (max-width: 1024px){
    .sidebarContainer{
        top: auto;
        left: 0;
        right: 0;
        bottom: -100%;
        align-items: center;
        padding: spacing(5) spacing(3.25) !important;
        transition: 0.5s ease-in-out;
        &.open{
            bottom: 0;
        }
        & .back {
            display: none;
        }
    }
}