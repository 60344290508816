@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.menuContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: spacing(3) spacing(2);
    width: 100%;
    & .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: spacing(1.5);
        padding: spacing(4) spacing(1.75) spacing(2.5);
        width: 240px;
        background: $backgroundColor;
        border-radius: $borderRadius;
        box-shadow: $boxshadow;
        cursor: pointer;
        &:hover{
            background: rgba($color: #FFFFFF, $alpha: 0.6);
        }
        &:active{
            background: rgba($color: #FFFFFF, $alpha: 0.75);
        }
        &.disabled {
            & .icon {
                color: $thirdFontColor;
            }
            & p {
                color: $thirdFontColor;
            }
        }
        & .icon {
            width: 70px;
            color: $mainFontColor;
        }
        & p {
            display: flex;
            flex-direction: column;
            gap: spacing(1.5);
            font-size: $extraSmallFontSize;
            color: $fontColor;
            text-align: center;
            margin: 0;
            & .title {
                font-size: $smallFontSize;
                font-weight: 500;
            }
            & .description {
                font-weight: 400;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .menuContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: start;
        flex-wrap: nowrap;
        gap: spacing(2);
        padding: 0 0 spacing(1);
        overflow-x: auto;
        & .option {
            gap: spacing(2);
            padding: spacing(3) spacing(2);
            width: 240px;
            & .icon {
                width: 50px;
            }
            & p {
                gap: spacing(1.5);
                font-size: $extraExtraSmallFontSize;
                color: $fontColor;
            }
        }
    }
}