@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.inputsContainer {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(2);
    margin: 0 0 spacing(4);
    width: 65%;
    border-radius: 20px;
    & .horizontalLine{
        width: 100%;
        height: 1px;
        background: $secondBackgroundColor;
    }
    & .carPrice{
        & > p{
            font-size: 64px !important;
        }
        & input{
            font-size: 64px !important;
        }
    }
    & > .errorMessage{
        font-size: $extraSmallFontSize;
        font-weight: 400;
        color: $primaryColor;
        margin: 0 0 spacing(1);
    }
    & > .costs{
        display: flex;
        flex-direction: column;
        gap: spacing(1.5);
        width: 100%;
        & > .message{
            font-size: $extraExtraSmallFontSize;
            font-weight: 400;
            color: $fontColor;
            margin: 0;
            text-align: center;
        }
        & .title{
            font-size: $smallFontSize;
            font-weight: 500;
            color: $fontColor;
            margin: 0;
            text-align: center;
            &.resume{
                margin: spacing(1) 0 0;
            }
        }
        & .item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: $extraSmallFontSize;
            font-weight: 400;
            color: $fontColor;
            margin: 0;
            &.totalAdministrativeCharges,
            &.totalDeducedCharges{
                & .value{
                    font-size: $normalFontSize;
                }
            }
            & .name{
                margin: 0;
            }
            & .bold{
                font-weight: 500;
            }
            & .skeleton{
                font-size: $smallFontSize;
                width: 60px;
            }
        }
    }
    & > .switch{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: spacing(2);
        & > .title{
            font-size: $smallFontSize;
            font-weight: 400;
            color: $fontColor;
            margin: 0;
        }
    }
}

.submitButton{
    margin: auto 0 0 !important;
}