@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';

.scheduleInspectionContainer {
    position: relative;
    display: flex;
    flex: 1;
    background: url("../../../assets/images/background_buy.webp") no-repeat center center;
    background-size: cover;
    justify-content: end;
    align-items: flex-start;
    z-index: 3;

    & .cardContainer {
        display: flex;
        flex-direction: column;
        gap: spacing(4);
        margin: spacing(8) spacing(12);
        padding: spacing(8) spacing(5);
        max-width: 740px;
        width: 100%;
        background-color: $backgroundColor;
        border-radius: $borderRadius;

        &>.title {
            font-size: $mediumFontSize;
            font-weight: 600;
            color: $fontColor;
            text-align: center;
            margin: 0;
        }
    }

    & .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: spacing(8);
        margin: 0 auto;
        max-width: 550px;
        width: 100%;

        & .subtitle {
            font-size: $normalFontSize;
            font-weight: 400;
            color: $fontColor;
            text-align: center;
            margin: 0;
        }

        & div {
            & .subtitlePickup {
                font-size: $normalFontSize;
                font-weight: 500;
                color: $fontColor;
                text-align: center;
                margin: 0;
            }

            & .addressPickUp {
                font-size: $normalFontSize;
                font-weight: 400;
                color: $fontColor;
                text-align: center;
                margin: spacing(1) 0;
            }
        }

        & .item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: spacing(2);
            width: 100%;

            & .content {
                display: flex;
                width: 100%;
            }

            &>.name {
                font-size: $mediumFontSize;
                font-weight: 500;
                color: $fontColor;
                margin: 0;
            }

            &.dates {
                & .content {
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    gap: spacing(2);
                }

                & .description {
                    font-size: $smallFontSize;
                    font-weight: 400;
                    color: $fontColor;
                    margin: 0 auto;
                    max-width: 344px;
                    text-align: center;

                    & span {
                        font-weight: 600;
                    }
                }
            }

            &.participants {
                & .content {
                    justify-content: center;
                    gap: spacing(5);

                    & .people {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: spacing(2);

                        &.you {
                            & img {
                                width: 72px;
                                height: 72px;
                                object-fit: cover;
                                border-radius: 100px;
                            }
                        }

                        &.linzer {
                            & .image {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: spacing(1.5);
                                width: 48px;
                                height: 48px;
                                background: #7100FF;
                                border-radius: 100px;

                                & img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                        }

                        & p {
                            font-size: $smallFontSize;
                            font-weight: 400;
                            color: $fontColor;
                            margin: 0;
                        }
                    }
                }

                & .description {
                    font-size: $normalFontSize;
                    font-weight: 400;
                    color: $fontColor;
                    text-align: center;
                }
            }
        }
    }
}

.buttonContainer {
    margin: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    & span{
        margin: 0 0 spacing(2) 0
    }
}

.modalContainerBuy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(6);

    & .message {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $fontColor;
        text-align: center;

        & .title {
            font-size: $largeFontSize;
            font-weight: 400;
        }

        & .subtitle {
            font-size: $normalFontSize;
            font-weight: 400;
            margin: spacing(4) 0 spacing(7);
            max-width: 316px;
        }

        & .description {
            font-size: $smallFontSize;
            font-weight: 400;
            max-width: 374px;
        }
    }
}

.modalValidDocuments {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(3);
    padding: 0 0 spacing(2);

    & div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: spacing(2);
    }

    & .message {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $fontColor;
        text-align: center;

        & .title {
            font-size: $largeFontSize;
            font-weight: 400;
        }

        & .subtitle {
            font-size: $normalFontSize;
            font-weight: 400;
            margin: spacing(4) 0 spacing(0);
        }
    }
}